//Admin status
.badge.badge-outlined.badge-payment_due-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $warning;
  border-color: $warning;
  color: white;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}

.badge.badge-outlined.badge-dispute_lost-admin,
.badge.badge-billable-admin,
.badge.badge-non_billable-admin,
.badge.badge-declined-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $danger;
  border-color: $danger;
  color: white;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.badge.badge-outlined.badge-dispute_won-admin,
.badge.badge-outlined.badge-paid-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $success;
  border-color: $success;
  color: white;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}

.badge.badge-outlined.badge-partially_refunded-admin,
.badge.badge-outlined.badge-refunded-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $dark-grey-2;
  border-color: $dark-grey-2;
  color: white;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}
.badge.badge-outlined.badge-disputed-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: $purple-2;
  border-color: $purple-2;
  color: white;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
}

//Client status
.badge.badge-outlined.badge-payment_due-client {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: rgba($warning, 0.1);
  border-color: $warning;
  color: $warning;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
  margin: 0px !important;
}

.badge.badge-dispute_won-client,
.badge.badge-billable-client,
.badge.badge-non_billable-client,
.badge.badge-transaction_declined-client,
.badge.badge-declined-client {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: rgba($danger, 0.1);
  border-color: $danger;
  color: $danger;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
  margin: 0px !important;
}
.badge.badge-dispute_lost-client,
.badge.badge-outlined.badge-paid-client {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: rgba($success, 0.1);
  border-color: $success;
  color: $success;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
  margin: 0px !important;
}
.badge.badge-outlined.badge-partially_refunded-client,
.badge.badge-outlined.badge-refunded-client {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: rgba($dark-grey-2, 0.1);
  border-color: $dark-grey-2;
  color: $dark-grey-2;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
  margin: 0px !important;
}
.badge.badge-outlined.badge-disputed-client {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: rgba($purple-2, 0.1);
  border-color: $purple-2;
  color: $purple-2;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
  margin: 0px !important;
}

.payment-block-height {
  height: 44px !important;
  border-bottom: 1px solid rgba($dark-grey, 0.2);
  // rgba(139, 149, 158, 0.25)
}

.payment-list {
  display: flex;
  flex-direction: column;
  // margin-bottom: 20px;
}
.payment-item {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 10px 0px;
  border-bottom: 1px solid rgba($dark-grey, 0.2);
}
.payment-item-charge {
  justify-content: space-between;
}
.payment-block-charge {
  font-size: 15px;
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 10px 0px;
  border-bottom: 1px solid rgba($dark-grey, 0.2);
}
.payment-amount-badge {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.payment-amount {
  font-weight: bold;
}
.payment-badge {
  display: flex;
  justify-content: flex-end;
  min-width: 130px;
  // align-items: center;
}

.payment-service-badge {
  display: flex;
  flex-direction: column;
}

.total {
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  font-weight: bold;
}

//Drop-down colours

@media (max-width: 768px) {
  /* Adjust to match Bootstrap's md breakpoint */
  .payment-badge {
    display: flex;
    justify-content: flex-start;
    min-width: 110px;
    margin-top: 12px;
  }

  .payment-amount-badge {
    display: flex;
    align-items: flex-start;
    white-space: nowrap;
  }
}

.header-charges {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  margin-bottom: 50px;
}
.sub-header-charges {
  font-size: 20px;
  font-weight: lighter;
  width: 100%;
  margin-bottom: 0px;
}
.drop-down-charges {
  float: right;
  padding-right: 10px;
}

.refund-history-item {
  display: flex;
  justify-content: space-between;
}

.refund-history-item:first-child {
  margin-top: 1rem;
}
.refund-history-item:not(:first-child) {
  margin-top: 1rem;
}
