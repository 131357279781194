.tooltip {
  background-color: white;
  .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: black;
    text-align: center;
    background-color: white;
    border-radius: 0.25rem;
  }
  .arrow {
    display: none;
  }
}
.userInitailsTip {
  .tooltip-inner {
    border: 1px solid black !important;
  }
}
.adminInitailsTip {
  .tooltip-inner {
    text-align: left !important;
    max-width: -webkit-fill-available !important;
    border: 1px solid black !important;
  }
}
.clientInfoTooltip {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px;
  .tooltip-inner {
    text-align: left;
    max-width: 300px;
    padding: 0.75rem 1rem;
    border: 1px solid rgba($dark, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 0.313rem !important;
    padding: 0 !important;

    .tooltip-inner {
      text-align: left;
      max-width: 18rem;
      padding: 0.75rem 1rem;
      border: 1px solid rgba($dark, 0.25);
      border-radius: 0.2rem;
      font-size: 14px;
      background-color: white;
    }
  }
}
.partnerRegistrationTooltip {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px;
  .tooltip-inner {
    text-align: left;
    max-width: 300px;
    padding: 0.75rem 1rem;
    border: 1px solid rgba($dark, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 0.313rem !important;
    padding: 0 !important;

    .tooltip-inner {
      text-align: left;
      max-width: 16rem;
      padding: 0.75rem 1rem;
      border: 1px solid rgba($dark, 0.25);
      border-radius: 0.2rem;
      font-size: 14px;
      background-color: white;
    }
  }
}
.partner-infoToolTip {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px;
  .tooltip-inner {
    text-align: left;
    max-width: 18rem;
    padding: 0.75rem 1rem;
    border: 1px solid rgba($dark, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white;
  }

  @media (max-width: 768px) {
    .tooltip-inner {
      text-align: left;
      max-width: 19.938rem;
      padding: 0.75rem 1rem;
      border: 1px solid rgba($dark, 0.25);
      border-radius: 0.2rem;
      font-size: 14px;
      background-color: white;
    }
  }
}
.clientInfoTooltip-coverage {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px;
  transform: translate3d(-12px, 274.667px, 0px);
  .tooltip-inner {
    text-align: left;
    padding: 0.75rem 1rem;
    border: 1px solid rgba($dark, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white;
    position: absolute;
    width: 182px;
    right: -51px;
    top: 14px;
  }
}
.clientInfoTooltip-terms {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px;
  transform: translate3d(-12px, 274.667px, 0px);
  .tooltip-inner {
    text-align: left;
    padding: 0.75rem 1rem;
    border: 1px solid rgba($dark, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white;
  }
}
.clientInfoTooltip-coverage-two {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px;
  left: -15% !important;
  .tooltip-inner {
    text-align: left;
    padding: 0.75rem 1rem;
    border: 1px solid rgba($dark, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white;
    position: absolute;
    width: 182px;
    right: -51px;
    top: 14px;
  }
}
.clientInfoTooltip-multi {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px;
  .tooltip-inner {
    text-align: left;
    max-width: 300px;
    padding: 0.75rem 1rem;
    padding-right: 0.5rem;
    padding-left: 0.8rem;
    border: 1px solid rgba($dark, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white;
  }
}

.clientInfoTooltip-childSeat {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px;
  .tooltip-inner {
    text-align: left;
    width: 200px;
    padding: 0.75rem 0rem 0.75rem 0.75rem;
    border: 1px solid rgba($dark, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white;
  }
}
.scott-dunn-tool-tip {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px;
  .tooltip-inner {
    text-align: left;
    max-width: 31.25rem;
    padding: 0.75rem 1rem;
    border: 1px solid rgba($dark, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white;
  }
  @media (max-width: 768px) {
    .tooltip-inner {
      text-align: left;
      max-width: 18.125rem;
      padding: 0.75rem 1rem;
      border: 1px solid rgba($dark, 0.25);
      border-radius: 0.2rem;
      font-size: 14px;
      background-color: white;
      margin-left: -8rem;
    }
  }
}

.referral-tool-tip {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px;
  background-color: unset;
  .tooltip-inner {
    text-align: left;
    max-width: 19rem;
    padding: 0.75rem 1rem;
    border: 1px solid rgba($dark, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white;
  }
  @media (max-width: 768px) {
    .tooltip-inner {
      text-align: left;
      max-width: 18.125rem;
      padding: 0.75rem 1rem;
      border: 1px solid rgba($dark, 0.25);
      border-radius: 0.2rem;
      font-size: 14px;
      background-color: white;
      margin-left: -8rem;
    }
  }
}
