.page-wrap {
  .nested-layout {
    &.admin-chat-layout {
      display: flex;
      flex-direction: column;
      height: 100vh;
      overflow: hidden;

      header {
        margin: 0;
        background-color: $light-3;
        .page-title {
          font-size: 24px;
        }
      }

      main {
        padding: 0;
        height: calc(100% - 48px);
        background-color: $white !important;
      }
    }
  }
}

.admin-chat-container {
  height: 100%;
  border-top: 1px solid #c1c1c1;
  display: grid;
  grid-template-columns: 420px 1fr;
  grid-gap: 10px;
  align-items: stretch;
  border-right: 1px solid #c1c1c1;
  @include media-breakpoint-down(sm) {
    border-right: none;
  }
  .user-avatar {
    min-width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    width: fit-content;

    &.user-online {
      border: 1px solid $success;
    }

    img {
      display: block;
      //width: 100%;
      //height: 100%;
      width: 34px;
      height: 34px;
      object-fit: cover;
      object-position: center center;

      &.rolzo-avatar {
        width: 34px;
        height: 34px;
      }
    }
  }

  .admin-chat-room {
    //display: flex;
    flex-direction: column;
    overflow: hidden;
    width: calc(100vw - 490px);

    .room-header {
      position: absolute;
      height: 48px;
      width: calc(100% - 431px);
      top: 0;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      border-left: 1px solid #c1c1c1;
      font-size: 12px;
      font-weight: 500;
      color: $dark;

      a:hover {
        text-decoration: none;
      }

      .user-avatar {
        margin-right: 10px;
      }

      .userLabel-plain {
        color: $black;
        font-weight: bold;
      }

      .userLabel-grey {
        color: $dark;
        text-align: center;
        font-weight: bold;
      }

      .userLabel-border-right {
        color: $black;
        padding: 0px 10px 0px 0px;
        border-right: 1px solid #8b959e;
        margin-right: 10px;
        font-weight: bold;
      }
    }

    .room-content {
      border-left: 1px solid #c1c1c1;
      height: 100%;
      position: relative;
      .admin-messages-list {
        overflow-y: auto;
        max-height: calc(100vh - 102px);
      }

      .message-input-wrap {
        textarea {
          padding-left: 102px !important;
          padding-top: 11px !important;
          font-size: 15px !important;
        }

        textarea::placeholder {
          color: #000000;
          opacity: 0.4;
        }

        textarea:-ms-input-placeholder {
          color: #000000;
          opacity: 0.4;
        }

        textarea::-ms-input-placeholder {
          color: #000000;
          opacity: 0.4;
        }
      }
    }

    .admin-messages-list {
      height: 100%;
      padding: 20px 65px 0 10px;

      .date-line {
        width: 100%;
        border-top: 1px solid #c1c1c1;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        padding-top: 5px;
        &.admin-chat {
          div {
            padding-inline: 8px;
          }
        }
        div {
          font-size: 14px;
          color: #8b959e;
          // text-transform: uppercase;
          background: $white;
          margin-top: -13px;
          width: 80px;
          text-align: center;
          white-space: nowrap;
        }
      }

      #options .hides {
        display: none;
      }

      #options:hover .hides {
        display: block;
        float: right;
        margin-top: -25px;
        padding: 5px;
        background-color: gainsboro;
      }

      .msg-editing {
        .cancel-btn {
          width: auto;
          position: relative;
          margin: 10px 10px;
          border: 1px solid #8b959e;
          height: 40px;
          display: inline-block;
          line-height: 20px;
          color: #8b959e !important;
          font-weight: 500;
          z-index: 50;
        }

        .save-btn {
          padding: 0px 1rem !important;
          position: relative;
          z-index: 50;
        }
      }

      .message {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 25px;
        padding-left: 5px;

        .user-avatar {
          order: 1;
          margin-right: 10px;
          flex-shrink: 0;
          border: 1px solid rgba($black, 0.1);
          object-fit: cover;
          object-position: center center;
        }

        .text-wrap {
          order: 2;
          max-width: 56%;
          background-color: $white;
          border-radius: 2px;
          box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
          padding: 12px;

          .text {
            margin-bottom: 4px;
            display: inline-block;
            width: 100%;
            word-break: break-word;
            font-size: 15px;
          }

          .message-info {
            font-size: 12px;
            color: rgba($dark, 0.5);
            text-align: right;
            display: inline;
          }

          .message-info-booked {
            font-size: 12px;
            color: rgba($dark, 0.5);
            float: right;
          }

          .download-link {
            color: #a79779;
            font-size: 10px;
            display: block;

            .pdf-icon {
              width: 11px;
              height: 11px;
              margin-right: 7px;
            }

            &:hover {
              text-decoration: none;
            }
          }
        }

        &.is-current {
          .text-wrap {
            background-color: rgba(#8b959e, 0.1);
            box-shadow: none;
          }
        }

        &.is-typing {
          align-items: center;
          visibility: visible;

          .user-avatar {
            order: 0;
          }
        }

        &.is-current {
          justify-content: flex-end;

          .user-avatar {
            order: 2;
            margin-right: 0;
            margin-left: 15px;
          }

          .text-wrap {
            order: 1;
          }
        }

        &.hidden {
          visibility: hidden;
        }

        &.displaynone {
          opacity: 0;
        }
      }
    }

    .admin-message-input-block {
      position: absolute;
      bottom: 0;
      padding: 5px 20px 5px 20px;
      border-top: 1px solid #c1c1c1;
      // width: calc(100vw - 40%);
      width: 100%;
      background: white;

      .chat-footer-container {
        max-width: 660px;
      }
    }

    .message-input-toolbar {
      position: relative;
      width: calc(100% - 80px);
      //flex-shrink: 0;
      //padding: 0 155px 40px 0;
      display: flex;

      //align-items: center;
      //position: fixed;
      //bottom: 110px;
      //height: 40px;
      //width: calc(100vw - 550px);
      .form-group {
        margin: 0;
      }

      .custom-file-label {
        height: 40px;
        left: 26px;

        .placeholder-icon {
          height: auto;
          width: 14px;
          margin-top: 2px;
        }
      }

      .message-input-wrap {
        position: relative;
        flex-grow: 1;
        height: 100%;
        margin-left: 4px;
        width: 100%;

        //max-width: calc(100vw - 570px);
        .custom-file,
        .custom-file-label {
          background-color: transparent;
        }

        .toolbar-buttons {
          position: absolute;
          left: 18px;
          top: 10px;
          display: flex;
          align-items: center;

          .toolbar-btn {
            &.car-btn {
              width: 24px;
              height: 24px;
              margin-right: 22px;
              z-index: 1;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }

      .send-avatar-wrap {
        display: none;
        position: relative;
        height: 100%;
        margin-top: 8px;
        margin-left: 8px;

        .send-avatar {
          top: 0;
          position: absolute;
          width: 36px;
          height: 36px;
          flex-shrink: 0;
          margin: auto;
          border: 1px solid rgba(12, 12, 12, 0.1);
          border-radius: 40px;
          object-position: center center;
          object-fit: cover;

          img {
            width: 20px;
            height: 20px;
            margin-top: 6px;
            margin-left: 7px;
          }
        }
      }

      .send-btn-wrap {
        position: relative;
        height: 100%;

        .send-btn {
          top: 0;
          position: absolute;
          width: 30px;
          height: 40px;
          margin: 0;
          margin-left: 20px;
          flex-shrink: 0;
          padding-top: 9px;

          &:hover {
            opacity: 0.8;
          }

          &:focus {
            box-shadow: none !important;
          }

          img {
            width: 24px;
            height: 20px;
            object-fit: contain;
          }
        }
      }
    }
  }

  .chats-list-wrap {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;

    .spinner_wrapper {
      top: 130px;
      z-index: 1;
    }

    .list-panel {
      height: 120px;
      flex-shrink: 0;
      padding-right: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .search-field-wrap {
        flex-grow: 1;

        .form-group {
          margin-bottom: 0;

          .form-control {
            height: 40px;
            box-shadow: none;
            border: 1px solid $dark-grey;
            font-size: 14px;
            padding: 0 16px;
            padding-left: 54px;
            background-image: url('/images/icons/chat/search-icon.png');
            background-position: center left 16px;
            background-size: 16px 16px;
            background-repeat: no-repeat;
          }
        }
      }

      .chat-sort-menu {
        padding: 10px 0;

        .dropdown-item {
          padding: 10px 20px;

          &:not(:last-child) {
            border-bottom: 0;
          }
        }
      }
    }

    .chats-list {
      height: 100%;
      overflow-y: auto;
      padding-right: 10px;
      padding-left: 5px;

      .chat {
        background-color: $light-2;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $black;
        font-size: 14px;
        border-right: 2px solid $light-2;
        text-decoration: none;
        padding: 10px 20px;
        margin-right: 15px;
        flex-wrap: wrap;

        &:not(:last-child) {
          margin-bottom: 9px;
        }

        &.chat-urgent {
          border-left: 2px solid $danger;
          margin-right: 15px;
        }
        &.chat-pending {
          border-left: 2px solid $warning;
          margin-right: 15px;
        }
        &.chat-progress {
          border-left: 2px solid $blue;
          margin-right: 15px;
        }
        &.chat-resolved {
          border-left: 2px solid $success;
          margin-right: 15px;
        }

        .admin-notes {
          flex: 0 0 100%;
          width: 100%;
        }
        .admin-notes-exist {
          flex: 0 0 100%;
          border-top: 1px solid rgba($dark-grey, 0.2);
          width: 100%;
          max-width: 100%;
        }
        &.add-border {
          border: 2px solid $black;
          margin-right: 15px;
        }

        // &:hover {
        //   opacity: 0.7;
        // }

        .user-info {
          .user-avatar-container {
            position: relative;
            display: inline-block;
          }

          .user-avatar {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .add-border {
            border: 2px solid $success !important;
          }

          .add-border-danger {
            border: 2px solid $danger;
          }

          .user-status-idle,
          .user-status-active,
          .user-status-inactive {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            position: absolute;
            bottom: 2px;
            right: 2px;
          }

          .booking-badge {
            display: inline-block;
            margin-bottom: 5px;
            // border: 1px solid $dark-grey;
            border: 1px solid $dark;
            border-radius: 2px;
            padding: 3px 10px 3px;
            // color: $dark-grey;
            color: $dark;
            font-size: 12px;
            margin-left: 10px;
            height: 25px;
          }

          .user-status-idle {
            background-color: $warning;
          }

          .user-status-active {
            background-color: $light-green;
          }

          .user-status-inactive {
            background-color: $dark;
          }

          .user-info-detailed {
            display: flex;
            align-items: end;
          }

          .user-name-chat {
            color: #000;
            font-size: 14px;
            font-weight: bold;
            white-space: initial;
            padding-top: 5px;
          }
          .company-time-chat {
            color: $black;
            font-size: 12px;
            font-weight: 500;
            white-space: initial;
            padding-top: 0 !important;
          }
          .company-name-chat {
            color: $dark;
            font-size: 12px;
            font-weight: 500;
            white-space: initial;
          }
        }

        .top-first {
          top: 1.1em;
        }
        .top-second {
          top: -0.5em;
        }
        .top-third {
          top: -1.5em;
        }
        .tooltip-container {
          display: flex;
          align-items: center;
          margin-top: 10px;
          margin-bottom: 5px;

          .urgent-badge {
            width: 16px;
            height: 16px;
            margin-right: 6px;
          }
        }
        .last-message-info {
          display: flex;
          align-items: end;
          flex-direction: column;
          justify-content: flex-end;
          flex-shrink: 0;
          font-size: 12px;
          text-align: right;
          margin-left: 10px;
          position: relative;

          .user-avatar-container {
            position: relative;
            display: inline-block;
          }

          .user-avatar {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            overflow: hidden;
            margin-left: 14px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .user-status-idle,
          .user-status-active,
          .user-status-inactive {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            position: absolute;
            bottom: 0;
            right: 0;
          }

          .user-status-idle {
            background-color: $warning;
          }

          .user-status-active {
            background-color: $light-green;
          }

          .user-status-inactive {
            background-color: $dark;
          }

          .unread-title {
            color: rgba($warning, 1);
            display: flex;

            span {
              font-weight: bolder;
              font-size: 13px;
              text-transform: uppercase;
              margin-left: 3px;
            }

            .unread-counter {
              display: inline-block;
              width: 24px;
              height: 24px;
              border: 1px solid rgba($warning, 1);
              border-radius: 20px;
              line-height: 24px;
              margin-left: 10px;
              text-align: center;
              font-weight: 600;
              font-size: 13px;
            }
          }

          .message-date {
            color: $dark-grey;
            font-style: italic;
          }
        }
      }
    }
  }
}

.modal-chat-body {
  padding: 30px;
}

.flag-btn {
  height: 30px;
}

.chat-search {
  font-size: 14px !important;
}

.message-info-bottom {
  .message-info {
    font-size: 12px;
    color: rgba($dark, 0.5);
    text-align: right;
    display: inline;
  }

  .message-info-booked {
    font-size: 12px;
    color: rgba($black, 1);
    float: right;
    text-align: right;

    img {
      width: 10px;
      vertical-align: text-top;
    }
  }

  .message-info-booked-event {
    font-size: 12px;
    color: rgba($black, 1);
    text-align: right;

    img {
      width: 10px;
      vertical-align: text-top;
    }
  }

  .message-info-booked-event-chat {
    font-size: 12px;
    color: rgba($black, 1);
    text-align: right;
    display: inline-block;
    float: right;
    margin-top: -5px;

    img {
      width: 10px;
      vertical-align: text-top;
    }
  }
}

.message-info-bottom-chat {
  .message-info {
    font-size: 12px;
    color: rgba($dark, 0.5);
    text-align: right;
    display: inline;
  }

  .message-info-booked {
    font-size: 12px;
    color: rgba($black, 1);
    float: right;
    text-align: right;

    img {
      width: 10px;
      vertical-align: text-top;
    }
  }

  .message-info-booked-event {
    font-size: 12px;
    color: rgba($black, 1);
    text-align: right;

    img {
      width: 10px;
      vertical-align: text-top;
    }
  }

  .message-info-booked-event-chat {
    font-size: 12px;
    color: rgba($black, 1);
    text-align: right;
    display: inline-block;
    float: right;
    margin-top: -5px;

    img {
      width: 10px;
      vertical-align: text-top;
    }
  }
}

.notes-cancel-btn {
  font-size: 15px;
  color: $danger;
  text-decoration: underline;
  text-underline-offset: 8px;
  margin-right: 12px;

  &:hover {
    color: $danger;
    text-decoration: underline;
    text-underline-offset: 8px;
  }
}

.notes-save-btn {
  font-size: 15px;
  color: $brown;
  text-decoration: underline;
  text-underline-offset: 8px;

  &:hover {
    color: $brown;
    text-decoration: underline;
    text-underline-offset: 8px;
  }
}
