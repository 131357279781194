.custom-tabs-nav {
  background: rgba($dark-grey, 0.15);
  padding: 7px 15px;
  border-radius: 4px;
  font-size: 13px;
  margin-bottom: 32px;

  .nav-link {
    background-color: transparent;
    color: rgba($dark-grey, 0.5);
    font-weight: 500;
    padding: 9px 18px;
    border-radius: 3px;

    &:hover {
      color: rgba($dark-grey, 0.7);
    }

    &.active {
      background-color: $white;
      color: $dark-3;
      box-shadow: $default-shadow;
    }
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
  }

  &.light-tabs {
    border-bottom: 1px solid rgba($dark-grey, 0.3);
    border-radius: 0;
    width: 100%;
    max-width: 1000px;
    background: transparent;
    font-size: 16px;
    padding: 0;
    .nav-link {
      background: transparent;
      border-radius: 0;
      font-size: 16px;
      font-weight: 600;
      color: $dark-grey;
      opacity: 0.55;
      padding: 7px 7px 14px 7px;
      border-color: transparent;
      box-shadow: none;

      &.active,
      &:focus {
        background: transparent;
        border-color: transparent;
        border-bottom: 3px solid $black;
        box-shadow: none;
        color: $dark-2;
        opacity: 1;
        margin-bottom: -2px;
        font-weight: 600;
      }

      &:not(:last-child) {
        margin-right: 25px;
      }
    }
  }
  &.light-tabs-partner {
    border-bottom: 0 !important;
    border-radius: 0;
    width: 100%;
    max-width: 1000px;
    background: transparent;
    font-size: 16px;
    padding: 0;
    .nav-link {
      background: transparent;
      border-radius: 0;
      font-size: 16px;
      font-weight: 600;
      color: $dark-grey;
      opacity: 0.55;
      padding: 7px 0px 14px 0px !important;

      &.active {
        background: transparent;
        //border-bottom: 3px solid $brown;
        border-bottom: 3px solid $black;
        box-shadow: none;
        color: $dark-2;
        opacity: 1;
        margin-bottom: -2px;
        font-weight: 600;
      }

      &:not(:last-child) {
        margin-right: 25px;
      }
    }
  }
  &.light-tabs-mobile {
    border-bottom: 1px solid rgba($dark-grey, 0.3);
    border-radius: 0;
    width: 100%;
    max-width: 1000px;
    background: transparent;
    font-size: 16px;
    padding: 0;
    .nav-link {
      background: transparent;
      border-radius: 0;
      font-size: 16px;
      font-weight: 600;
      color: $dark-grey;
      opacity: 0.55;
      padding: 7px 0px 14px 0px;

      &.active {
        background: transparent;
        //border-bottom: 3px solid $brown;
        border-bottom: 3px solid $black;
        box-shadow: none;
        color: $dark-2;
        opacity: 1;
        margin-bottom: -2px;
        font-weight: 600;
      }

      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}

.chat-tabs-nav {
  background: rgba($dark-grey, 0.15);
  padding: 7px 15px;
  border-radius: 4px;
  font-size: 13px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;

  .nav-link {
    background-color: transparent;
    color: rgba($dark-grey, 0.5);
    font-weight: 500;
    padding: 9px 18px;
    border-radius: 3px;
    flex: 1;
    text-align: center;

    &:hover {
      color: rgba($dark-grey, 0.7);
    }

    &.active {
      background-color: $white;
      color: $dark-3;
      box-shadow: $default-shadow;
    }
  }

  &.light-tabs {
    border-bottom: 1px solid #c1c1c1;
    border-top: 1px solid #c1c1c1;
    border-radius: 0;
    width: 100%;
    max-width: 100vw;
    background: transparent;
    font-size: 16px;
    padding: 0;
    padding-top: 10px;
    margin-bottom: 10px !important;
    display: flex;
    justify-content: space-between;

    .nav-link {
      background: transparent;
      border-radius: 0;
      font-size: 16px;
      font-weight: 600;
      color: $dark-grey;
      border: none;
      opacity: 0.55;
      padding: 7px 7px 14px 7px;
      flex: 1;
      text-align: center;

      &.active,
      &:focus {
        background: transparent;
        border: none;
        border-bottom: 3px solid $black;
        box-shadow: none !important;
        color: $dark-2;
        opacity: 1;
        margin-bottom: -2px;
        font-weight: 600;
      }

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}

.light-tabs-content {
  max-width: 1002px;
  .row {
    max-width: 1028px;
  }
}

.display-inline-left {
  display: inline-block;
}

.display-inline-right {
  display: inline-block;
  float: right;
}
