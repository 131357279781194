.form-control::-webkit-input-placeholder {
  color: rgba($black, 0.33);
}
input,
select {
  &.form-control,
  &.react-phone-number-input__input.react-phone-number-input__phone {
    height: 50px;
    padding: 0 10px;
    background-color: $light-3;
    // background-color: $white;
    color: $black;
    font-size: 14px;
    font-weight: 400;
    border: $form-control-border;
    border-radius: 2px;
    box-shadow: none;
    box-shadow: $default-shadow !important;

    &.is-invalid {
      background-image: none;
    }

    &:hover,
    &:focus {
      outline: none;
      border-color: $black;
      box-shadow: $default-shadow;
      background-color: $light-2;

      &.is-invalid {
        box-shadow: $default-shadow;
        border-color: $red;
      }
    }

    &:read-only,
    &:disabled {
      border: $form-control-border;
      box-shadow: none;
      background-color: $light-2;
    }

    &.light-bg-control {
      // background-color: $light-2;
      background-color: $white;
      font-size: 13px;
      color: rgba($black, 0.6);
      border: $form-control-border;
      padding: 15px 15px 20px;

      &:hover,
      &:focus {
        border-color: $brown;
      }

      &:read-only,
      &:disabled {
        border: $form-control-border;
        box-shadow: none;
        pointer-events: none;
        background-color: $light-2;
      }
    }

    &.white-field-input {
      // background-color: white;
      background-color: $white;
      border: 0.5px solid rgba(#8c969f, 0.3);

      &:hover,
      &:focus {
        border: 0.5px solid rgba(#8c969f, 1) !important;
      }
    }

    &.black-field-input {
      // background-color: white;
      background-color: $white;
      border: 0.5px solid rgba(#8c969f, 0.3);
      &::placeholder {
        color: black;
      }

      &:hover,
      &:focus {
        border: 0.5px solid rgba(#8c969f, 1) !important;
      }
    }

    &.location-autocomplete-control {
      &.show-pin {
        padding-right: 25px;
        background-image: url('/images/icons/pin.png');
        background-repeat: no-repeat;
        background-size: 12px 15px;
        background-position: center right 7px;
        color: $black;
        &:hover,
        &:focus {
          //color: rgba($black, 0.3);
        }
        &.is-invalid {
          border-color: $red;
        }
        &.dis-active {
          color: rgba($black, 0.3);
        }
        &.active {
          color: $black;
          background-image: url('/images/icons/pin_active.png') !important;
        }
      }
      &.flightTracking-loader-register {
        padding-right: 30px;
        // background-image: url('/images/icons/pin.png');
        background-image: url('/images/planeIcons/planeLoader.svg') !important;
        background-repeat: no-repeat;
        background-size: 15px 20px;
        background-position: center right 8px;
        color: $black;
      }
    }
    &.m-location-autocomplete-control {
      &.show-pin {
        padding-right: 25px;
        background-image: url('/images/icons/pin.png');
        background-repeat: no-repeat;
        background-size: 12px 15px;
        background-position: center left 28px;
        color: $black;
        &:hover,
        &:focus {
          //color: rgba($black, 0.3);
        }
        &.is-invalid {
          border-color: $red;
        }
        &.dis-active {
          color: rgba($black, 0.3);
        }
        &.active {
          color: $black;
          background-image: url('/images/icons/pin_active.png') !important;
        }
      }
    }
  }

  &.custom-datepicker-control {
    background-image: url('/images/icons/calendar.png');
    background-size: 12px 12px;
    background-position: center right 7px !important;
    background-repeat: no-repeat;
    padding-right: 30px;
    color: rgba($black, 0.3);
    &:read-only {
      background-color: $light-3;
      // background-color: $white;
      cursor: pointer;
      box-shadow: $default-shadow;

      &:hover,
      &:focus {
        outline: none;
        box-shadow: $default-shadow;
        background-color: $light-2;
        border-color: $black;
      }

      &.is-invalid {
        border-width: 0.5px;
        border-color: $red;
        background-image: url('/images/icons/calendar.png');
        background-size: 19px 19px;
        box-shadow: $default-shadow;

        //&:hover,
        //&:focus {
        //  background-image: url('/images/icons/calendar_active.png');
        //}
      }
    }
    &.active {
      background-image: url('/images/icons/calendar_active.png');
      color: $black;
    }
    &:hover,
    &:focus {
      //background-image: url('/images/icons/calendar_active.png');
    }

    &:disabled {
      background-image: url('/images/icons/calendar.png');
    }

    &.custom-timepicker-control {
      background-image: url('/images/icon-duration-inactive.svg');
      color: rgba($black, 0.3);
      &:hover,
      &:focus {
        //background-image: url('/images/icons/clock_active.svg');
      }
      &.active {
        background-image: url('/images/icon-duration-active.png');
        color: $black;
      }
      &:disabled {
        background-image: url('/images/icon-duration-inactive.svg');
      }

      &.is-invalid {
        background-image: url('/images/icon-duration-inactive.svg');

        &:hover,
        &:focus {
          //background-image: url('/images/icons/clock_active.svg');
        }
      }
    }

    &.show-clear {
      background-image: none;

      &:hover,
      &:focus {
        background-image: none;
      }
    }
  }

  &.react-phone-number-input__input.react-phone-number-input__phone {
    //padding-left: 40px;
  }

  &.grey-field-input {
    background-color: $light-3 !important;
  }
  &.grey-field-input-l3 {
    background-color: $light-3;
  }
}

.phone-number-flag {
  flex: 0 0 auto;
  padding-right: 5px;
}
.phone-number-input {
  //flex: 0 0 75%;
  flex: 0 0 calc(100% - 124px);
  padding-left: 5px;
}
.phone-number-select {
  position: absolute !important;
  width: 420px !important;
  .react-select__control {
    display: none;
  }
  .react-select-filters__control {
    display: none;
  }
}
button.dropdown-btn {
  height: 50px;
  color: $black;
  background-color: $light-2;
  box-shadow: $default-shadow;
  border: 1px solid transparent;
  padding: 2px 9px 2px 10px;
  width: 100%;
  &:hover,
  &:focus,
  &:active {
    background-color: $light-2 !important;
    box-shadow: $default-shadow !important;
    border: 1px solid $black !important;
    color: $black !important;
  }
  span {
    text-align: left;
    width: 40px;
    padding-left: 10px;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    position: relative;
  }
}
.fixed-top {
  padding-top: 4px;
  z-index: 0;
}
img {
  &.icon-flag {
    width: 24px;
    height: 18px;
    margin-right: 5px;
  }
  &.dropdown-svg {
    margin-top: 14px !important;
    margin-left: 12px;
  }
}

select {
  &.form-control {
    -webkit-appearance: none;
    background-image: url('/images/icons/caret-down.svg');
    background-size: 10px 10px;
    background-repeat: no-repeat;
    background-position: center right 10px;
  }
}

.company-logo-wrap {
  .custom-file {
    background-color: transparent !important;
  }
}

.custom-file,
.custom-file-label {
  height: 50px;
  padding: 0 10px 0;
  background-color: $light-3;
  cursor: pointer;
}

.custom-file-input {
  display: none;
}

.custom-file-label {
  color: $brown;
  font-size: 10px;
  font-weight: 400;
  border: $form-control-border;
  border-radius: 2px;
  box-shadow: $default-shadow;
  text-transform: none;
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
  }

  .file-name {
    letter-spacing: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    white-space: nowrap;
  }

  &:after {
    display: none;
  }

  &:hover,
  &:focus {
    outline: none;
    border-color: $brown;
    box-shadow: $default-shadow;
    background-color: $light-3;

    &.is-invalid {
      box-shadow: $default-shadow;
    }
  }
}

.custom-file {
  &.disabled {
    .custom-file-label {
      box-shadow: none;
      border: $form-control-border;
      background-color: $light-2;
    }
  }
}

.no-visible {
  display: none;
}

.chat-paperclip {
  width: 40px;
  height: 46px;
  //margin: 2px 0 2px;
  position: absolute;
  left: 8px;

  &.admin-chat {
    width: 60px;
    &.chat-paperclip {
      left: 18px;
    }
    .custom-file-label {
      display: flex;
      align-items: center;
      left: 36px !important;
    }
  }
  .custom-file-label {
    box-shadow: none;
    display: block;
    border: none;
    height: 42px;
    padding: 0 !important;
    &:hover {
      border: none;
      background: transparent;
    }
  }
}

label {
  line-height: 12px;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: $dark-grey-2;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem;
}
.checked {
  .custom-control-label {
    color: $black;
  }
}
.options {
  .custom-control-label {
    color: $black;
    padding-top: 3px !important;
  }
}
.custom-radio,
.custom-checkbox {
  display: flex;
  min-height: 15px;
  z-index: 0 !important;
  .custom-control-label {
    flex-grow: 1;
    padding-top: 2px;
    cursor: pointer;
    text-transform: none;
    letter-spacing: initial;
    &:before,
    &:after {
      top: 0;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }

    &:before {
      border: none;
      background-color: transparent;
    }

    &:after {
      background-size: 15px 15px;
    }
  }

  .custom-control-input {
    display: none;
    &:checked {
      ~ .custom-control-label {
        &::before {
          border: none;
          background-color: transparent;
        }
      }
    }
  }
}

.custom-radio-partner {
  .custom-control-label {
    &:before {
      width: 18px !important;
      height: 18px !important;
    }
    &:after {
      background-size: 18px 18px !important;
      width: 18px !important;
      height: 18px !important;
    }
  }
}

.custom-radio {
  margin-bottom: 10px;
  .custom-control-label {
    &:after {
      background-image: url('/images/icons/radio.svg');
    }
  }

  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &:after {
          background-image: url('/images/icons/radio-checked.svg');
        }
      }
    }
  }
}

.custom-checkbox {
  z-index: 0 !important;
  .custom-control-label {
    font-weight: 400;
    font-size: 15px !important;
    color: $black;
    padding-top: 0.2rem;
    // font-weight: 900;
    &:after {
      border-radius: 2px;
      background-color: $light-2;
      border: 1px solid $dark-grey;
      background-size: 8px 11px;
      background-position: center center;
    }
  }

  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &:after {
          background-image: url('/images/icons/check.svg');
        }
      }
    }
  }
}

.passenger-dropdown-checkbox {
  white-space: pre;
  .custom-control-label {
    color: $black !important;
    white-space: pre;
  }
  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &:after {
          border: 1px solid $black;
        }
      }
    }
  }
}

.com-popUp {
  .custom-control-label {
    color: $dark-grey;
  }
}
textarea.form-control {
  border: $form-control-border;
  background-color: $light-3;
  min-height: 160px;
  overflow: hidden;
  border-radius: 2px;
  box-shadow: $default-shadow;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  // caret-color: $brown;

  &:hover,
  &:focus {
    outline: none;
    border-color: $black;
    box-shadow: $default-shadow;
    background-color: $light-2;

    &.is-invalid {
      box-shadow: $default-shadow;
    }
  }
}
.textareaMobileFont {
  font-size: 16px;
}

.text-muted.textarea-hint {
  margin-top: 7px;
  font-size: 12px;
  color: #b9b9b9 !important;
  line-height: 20px;
}

.change-req-field {
  height: 50px;
  padding: 12px;
  padding-top: 14px;
  color: #0c0c0c;
  font-size: 14px;
  font-weight: 400;
  border: 0.5px solid transparent;
  border-radius: 2px;
  box-shadow: none;
  box-shadow: $default-shadow !important;
  background-color: rgba(139, 149, 158, 0.1) !important;
}

.react-select-container {
  width: 100%;
  font-size: 14px;
  &.is-invalid {
    .react-select__control {
      border: 0.5px solid $danger;
      &:hover,
      &:focus {
        border-color: $red;
      }
    }
  }

  .react-select__control {
    height: 50px;
    border-radius: 2px 2px 0 0;
    border: $form-control-border;
    box-shadow: $default-shadow;
    background-color: $light-3;
    padding: 0;
    &--is-focused,
    &--menu-is-open,
    &:hover {
      border-color: $black;
      background-color: $light;
    }

    &--menu-is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      img {
        transform: rotate(180deg) !important;
      }
    }

    &--is-disabled {
      // background-color: $light;
      background-color: rgba($color: $dark-grey, $alpha: 0.1) !important;
    }

    .react-select__indicator-separator {
      display: none;
    }

    .react-select__dropdown-indicator {
      padding: 0 5px;
      img {
        width: 17px;
        height: 17px;
      }
    }
    .react-select__dropdown-indicator-creatable {
      padding: 0 10px !important;
      img {
        width: 10px !important;
        height: 10px !important;
      }
    }
    .react-select__dropdown-indicator-clearable-filter {
      padding: 0 10px !important;
      img {
        width: 10px !important;
        height: 10px !important;
      }
    }
    .react-select__dropdown-indicator-carrot-filter {
      padding: 0 10px !important;
      img {
        width: 17px !important;
        height: 17px !important;
      }
    }

    .react-select__single-value {
      width: 100%;
      color: $black;
    }

    .select-multiple-value {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .react-select__value-container {
      &--is-multi {
        flex-wrap: nowrap;

        .select-all-btn {
          display: flex;
          align-items: center;
          overflow: hidden;
          cursor: pointer;

          &:before {
            content: '';
            display: inline-block;
            width: 15px;
            min-width: 15px;
            height: 15px;
            background-image: url('/images/icons/radio.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 15px;
          }

          &.selected-all {
            &:before {
              background-image: url('/images/icons/radio-checked.svg');
            }
          }

          .btn-title {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }

      .react-select__input {
        input {
          -webkit-box-sizing: border-box; // Safari/Chrome, other WebKit
          -moz-box-sizing: border-box; // Firefox, other Gecko
          box-sizing: border-box; // Opera/IE 8+
          border: 0 !important;
          width: 8.938rem !important;
        }
      }
    }

    .react-select__placeholder {
      color: rgba($black, 0.3);
    }
  }

  .react-select__menu {
    margin-top: 7px;
    border: 1px solid rgba($black, 0.1);
    box-shadow: none;
    border-radius: 0;
    border-radius: 0 0 2px 2px !important;
    z-index: 2;
    .react-select__option {
      padding: 10px;
      color: $black;
      cursor: pointer;
      border: none;
      &:hover,
      &--is-focused {
        background-color: rgba($black, 0.1);
      }

      &--is-selected {
        color: $white;
        background-color: $black;

        &:hover {
          background-color: rgba($black, 0.1);
        }
      }
    }

    &-list {
      padding: 0;
      margin-left: -1px;

      &--is-multi {
        .selectOptionCheckbox {
          // .react-select__option {
          //   display: flex;
          //   align-items: center;

          //   flex-grow: 1;
          //   padding-top: 2px;
          //   cursor: pointer;
          //   text-transform: none;
          //   letter-spacing: initial;

          //   .check-box-option {
          //     width: 15px;
          //     height: 15px;
          //     margin-right: 0.625em;
          //     &:checked {
          //       ~ .custom-control-label {
          //         &::before {
          //           border: none;
          //           background-color: transparent;
          //         }
          //       }
          //     }
          //   }
          //   .check-box-label {
          //     font-weight: 400;
          //     font-size: 15px !important;
          //     color: #0c0c0c;
          //   }

          //   &::before {
          //     content: none;
          //     background-image: none;
          //   }

          //   // &:before,
          //   // &:after {
          //   //   top: 0;
          //   //   width: 15px;
          //   //   height: 15px;
          //   //   cursor: pointer;
          //   // }

          //   // &:before {
          //   //   content: '';
          //   //   display: inline-block;
          //   //   width: 15px;
          //   //   min-width: 15px;
          //   //   height: 15px;
          //   //   border: none;
          //   //   background-color: transparent;
          //   //   background-image: none;
          //   // }

          //   // &:after {
          //   //   background-size: 15px 15px;
          //   // }
          // }
          .react-select__option {
            display: flex;
            align-items: center;

            &:before {
              content: '';
              display: inline-block;
              width: 15px;
              min-width: 15px;
              height: 15px;
              background-image: none;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              margin-right: 15px;

              border-radius: 2px;
              background-color: #fbfbfb;
              border: 1px solid #8b959e;
              background-size: 8px 11px;
              background-position: center center;
              margin-bottom: 0.2rem;
            }

            &--is-selected {
              color: $black;
              background-color: transparent;

              &:hover {
                background-color: rgba(12, 12, 12, 0.1);
              }

              &:before {
                background-image: url('/images/icons/check.svg');
              }
            }
          }
        }

        .react-select__option {
          display: flex;
          align-items: center;

          &:before {
            content: '';
            display: inline-block;
            width: 15px;
            min-width: 15px;
            height: 15px;
            background-image: url('/images/icons/radio.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 15px;
          }

          &--is-selected {
            color: $black;
            background-color: transparent;

            &:hover,
            &--is-focused {
              background-color: rgba($brown, 0.3);
            }

            &:before {
              background-image: url('/images/icons/radio-checked.svg');
            }
          }
        }
      }
    }
  }
}

.react-select-filters-container {
  width: 100%;
  font-size: 14px;
  &.is-invalid {
    .react-select-filters__control {
      border: 1px solid $danger;
      &:hover,
      &:focus {
        border-color: $red;
      }
    }
  }
  &.hasValue {
    .react-select-filters__control {
      border-color: $black !important;
    }
  }

  .react-select-filters__control {
    height: 38px;
    border-radius: 2px;
    // border: $form-control-border;
    border: 1px solid rgba($dark-grey, 0.1);
    box-shadow: $default-shadow;
    // background-color: $light;
    background: #fff;
    padding: 0;
    // border-color: rgba($black, 0.3);
    &--is-focused,
    &--menu-is-open,
    &:hover {
      border-color: $black;
      // background-color: $light;
    }

    &--menu-is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &--is-disabled {
      background-color: $light;
    }
    .react-select-filters__value-container--has-value {
      border-color: $black;
    }
    // .react-select-filters__indicators{
    //   border-left: 1px solid black;
    // }
    .react-select-filters__clear-indicator {
      border-left: 1px solid black;
    }
    .react-select-filters__indicator-separator {
      display: none;
    }

    .react-select-filters__dropdown-indicator {
      padding: 0 5px;
      img {
        width: 17px;
        height: 17px;
      }
    }
    .react-select-filters__dropdown-indicator-creatable {
      padding: 0 3px !important;
      img {
        width: 10px !important;
        height: 10px !important;
      }
    }
    .react-select-filters__dropdown-indicator-clearable-filter {
      padding: 0 3px !important;
      img {
        width: 8px !important;
        height: 8px !important;
        margin-bottom: 1px;
      }
    }
    .react-select-filters__dropdown-indicator-carrot-filter {
      padding: 0 3px !important;
      img {
        width: 17px !important;
        height: 17px !important;
      }
    }

    .react-select-filters__single-value {
      color: $black;
    }

    .select-multiple-value {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .react-select-filters__value-container {
      &--is-multi {
        flex-wrap: nowrap;

        .select-all-btn {
          display: flex;
          align-items: center;
          overflow: hidden;
          cursor: pointer;

          &:before {
            content: '';
            display: inline-block;
            width: 15px;
            min-width: 15px;
            height: 15px;
            background-image: url('/images/icons/radio.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 15px;
          }

          &.selected-all {
            &:before {
              background-image: url('/images/icons/radio-checked.svg');
            }
          }

          .btn-title {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }

    .react-select-filters__placeholder {
      // color: rgba($black, 0.3);
      color: $black;
      font-size: 14px;
      padding-top: 2px;
    }
  }

  .react-select-filters__menu {
    margin-top: 7px;
    border: 1px solid rgba($black, 0.1);
    box-shadow: none;
    border-radius: 0;
    z-index: 2;
    .react-select-filters__option {
      padding: 10px;
      color: $black;
      cursor: pointer;
      border: none;
      &:hover,
      &--is-focused {
        background-color: rgba($black, 0.1);
      }

      &--is-selected {
        color: $white;
        background-color: $black;

        &:hover {
          background-color: rgba($black, 0.1);
        }
      }
    }

    &-list {
      padding: 0;
      margin-left: -1px;

      &--is-multi {
        .react-select-filters__option {
          display: flex;
          align-items: center;

          &:before {
            content: '';
            display: inline-block;
            width: 15px;
            min-width: 15px;
            height: 15px;
            background-image: url('/images/icons/radio.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 15px;
          }

          &--is-selected {
            color: $black;
            background-color: transparent;

            &:hover,
            &--is-focused {
              background-color: rgba($brown, 0.3);
            }

            &:before {
              background-image: url('/images/icons/radio-checked.svg');
            }
          }
        }
      }
    }
  }
}
.react-currencySelect-container,
.react-currencySelect__control--is-focused {
  background: transparent;
  background-color: transparent !important;
}
.react-currencySelect-container {
  width: 100%;
  font-size: 14px;
  background: transparent;
  // z-index: 56;
  z-index: 1;
  &.is-invalid {
    .react-currencySelect__control {
      border: 1px solid $danger;
      background: transparent;
      &:hover,
      &:focus {
        border-color: $red;
      }
    }
  }

  .react-currencySelect__control {
    height: 50px;
    border-radius: 2px;
    border: $form-control-border;
    box-shadow: $default-shadow;
    // background-color: $light-2;
    background-color: red;
    padding: 0;
    background: transparent;
    &--is-focused,
    &--menu-is-open,
    &:hover {
      border-color: $black;
      background-color: transparent;
    }

    &--menu-is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &--is-disabled {
      background-color: $light;
    }

    .react-currencySelect__indicator-separator {
      display: none;
    }

    .react-currencySelect__dropdown-indicator {
      padding: 0 5px;
      img {
        width: 17px;
        height: 17px;
      }
    }
    .react-currencySelect__dropdown-indicator-creatable {
      padding: 0 10px !important;
      img {
        width: 10px !important;
        height: 10px !important;
      }
    }

    .react-currencySelect__single-value {
      color: $black;
    }

    .select-multiple-value {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    // .react-currencySelect__value-container-hide{
    //   z-index: 0 !important;
    // }
    .react-currencySelect__value-container {
      z-index: -1;
      &--is-multi {
        flex-wrap: nowrap;

        .select-all-btn {
          display: flex;
          align-items: center;
          overflow: hidden;
          cursor: pointer;

          &:before {
            content: '';
            display: inline-block;
            width: 15px;
            min-width: 15px;
            height: 15px;
            background-image: url('/images/icons/radio.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 15px;
          }

          &.selected-all {
            &:before {
              background-image: url('/images/icons/radio-checked.svg');
            }
          }

          .btn-title {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }

    .react-currencySelect__placeholder {
      color: rgba($black, 0.3);
      z-index: 1;
    }
  }
  .react-currencySelect__control--menu-is-open {
    .react-currencySelect__value-container {
      z-index: 0;
    }
  }
  .react-currencySelect__menu {
    margin-top: 7px;
    border: 1px solid rgba($black, 0.1);
    box-shadow: none;
    border-radius: 0;
    z-index: -2;
    .react-select__option {
      padding: 10px;
      color: $black;
      cursor: pointer;
      border: none;
      &:hover,
      &--is-focused {
        background-color: rgba($black, 0.1);
      }

      &--is-selected {
        color: $white;
        background-color: $black;

        &:hover {
          background-color: rgba($black, 0.1);
        }
      }
    }
    .react-currencySelect__option {
      background-color: $white;
      &:hover,
      &--is-focused {
        background-color: rgba($black, 0.1);
      }
    }
    .react-select-filters__option {
      padding: 10px;
      color: $black;
      cursor: pointer;
      border: none;
      &:hover,
      &--is-focused {
        background-color: rgba($black, 0.1);
      }

      &--is-selected {
        color: $white;
        background-color: $black;

        &:hover {
          background-color: rgba($black, 0.1);
        }
      }
    }

    &-list {
      padding: 0;
      margin-left: -1px;

      &--is-multi {
        .react-select__option {
          display: flex;
          align-items: center;

          &:before {
            content: '';
            display: inline-block;
            width: 15px;
            min-width: 15px;
            height: 15px;
            background-image: url('/images/icons/radio.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 15px;
          }

          &--is-selected {
            color: $black;
            background-color: transparent;

            &:hover,
            &--is-focused {
              background-color: rgba($brown, 0.3);
            }

            &:before {
              background-image: url('/images/icons/radio-checked.svg');
            }
          }
        }
        .react-select-filters__option {
          display: flex;
          align-items: center;

          &:before {
            content: '';
            display: inline-block;
            width: 15px;
            min-width: 15px;
            height: 15px;
            background-image: url('/images/icons/radio.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 15px;
          }

          &--is-selected {
            color: $black;
            background-color: transparent;

            &:hover,
            &--is-focused {
              background-color: rgba($brown, 0.3);
            }

            &:before {
              background-image: url('/images/icons/radio-checked.svg');
            }
          }
        }
      }
    }
  }

  &.payment-currency-settings {
    z-index: 0 !important;
  }
}
.react-countrySelect-container {
  width: 100%;
  font-size: 14px;
  background: transparent;
  z-index: 60;
  &.is-invalid {
    .react-currencySelect__control {
      border: 1px solid $danger;
      background: transparent;
      &:hover,
      &:focus {
        border-color: $red;
      }
    }
  }

  .react-currencySelect__control {
    height: 50px;
    border-radius: 2px;
    border: $form-control-border;
    box-shadow: $default-shadow;
    // background-color: $light-2;
    background-color: red;
    padding: 0;
    background: transparent;
    &--is-focused,
    &--menu-is-open,
    &:hover {
      border-color: $black;
      background-color: transparent;
    }

    &--menu-is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &--is-disabled {
      background-color: $light;
    }

    .react-currencySelect__indicator-separator {
      display: none;
    }

    .react-currencySelect__dropdown-indicator {
      padding: 0 5px;
      img {
        width: 17px;
        height: 17px;
      }
    }
    .react-currencySelect__dropdown-indicator-creatable {
      padding: 0 10px !important;
      img {
        width: 10px !important;
        height: 10px !important;
      }
    }

    .react-currencySelect__single-value {
      color: $black;
    }

    .select-multiple-value {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    // .react-currencySelect__value-container-hide{
    //   z-index: 0 !important;
    // }
    .react-currencySelect__value-container {
      z-index: -1;
      &--is-multi {
        flex-wrap: nowrap;

        .select-all-btn {
          display: flex;
          align-items: center;
          overflow: hidden;
          cursor: pointer;

          &:before {
            content: '';
            display: inline-block;
            width: 15px;
            min-width: 15px;
            height: 15px;
            background-image: url('/images/icons/radio.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 15px;
          }

          &.selected-all {
            &:before {
              background-image: url('/images/icons/radio-checked.svg');
            }
          }

          .btn-title {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }

    .react-currencySelect__placeholder {
      color: rgba($black, 0.3);
      z-index: 1;
    }
  }
  .react-currencySelect__control--menu-is-open {
    .react-currencySelect__value-container {
      z-index: 0;
    }
  }
  .react-currencySelect__menu {
    margin-top: 7px;
    border: 1px solid rgba($black, 0.1);
    box-shadow: none;
    border-radius: 0;
    z-index: -2;
    .react-select__option {
      padding: 10px;
      color: $black;
      cursor: pointer;
      border: none;
      &:hover,
      &--is-focused {
        background-color: rgba($black, 0.1);
      }

      &--is-selected {
        color: $white;
        background-color: $black;

        &:hover {
          background-color: rgba($black, 0.1);
        }
      }
    }
    .react-currencySelect__option {
      background-color: $white;
    }
    .react-select-filters__option {
      padding: 10px;
      color: $black;
      cursor: pointer;
      border: none;
      &:hover,
      &--is-focused {
        background-color: rgba($black, 0.1);
      }

      &--is-selected {
        color: $white;
        background-color: $black;

        &:hover {
          background-color: rgba($black, 0.1);
        }
      }
    }

    &-list {
      padding: 0;
      margin-left: -1px;

      &--is-multi {
        .react-select__option {
          display: flex;
          align-items: center;

          &:before {
            content: '';
            display: inline-block;
            width: 15px;
            min-width: 15px;
            height: 15px;
            background-image: url('/images/icons/radio.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 15px;
          }

          &--is-selected {
            color: $black;
            background-color: transparent;

            &:hover,
            &--is-focused {
              background-color: rgba($brown, 0.3);
            }

            &:before {
              background-image: url('/images/icons/radio-checked.svg');
            }
          }
        }
        .react-select-filters__option {
          display: flex;
          align-items: center;

          &:before {
            content: '';
            display: inline-block;
            width: 15px;
            min-width: 15px;
            height: 15px;
            background-image: url('/images/icons/radio.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 15px;
          }

          &--is-selected {
            color: $black;
            background-color: transparent;

            &:hover,
            &--is-focused {
              background-color: rgba($brown, 0.3);
            }

            &:before {
              background-image: url('/images/icons/radio-checked.svg');
            }
          }
        }
      }
    }
  }
}

.location-autocomplete-result,
.autocomplete-result {
  width: 100%;
  // background-color: $light-2;
  position: absolute;
  z-index: 2;
  background-color: $white !important;
  font-size: 13px;
  border: 1px solid rgba($black, 0.1);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-top: 6px;
  left: 0;
  .location-autocomplete-item,
  .autocomplete-item {
    padding: 10px 10px;
    cursor: pointer;
    &.active {
      // background-color: rgba($black, 0.1);
      background-color: $white !important;
    }
  }
}

.wrap-autocomplete > div {
  width: 100%;
}

.invalid-feedback {
  text-align: right;
}

.react-phone-number-input {
  .react-phone-number-input__row {
    position: relative;
  }
  .react-phone-number-input__country {
    padding: 10px;

    .react-phone-number-input__icon {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;

      &-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .international-icon {
        width: 15px;
        height: 15px;
        background-image: url('/images/icons/phone.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    &-select,
    &-select-arrow {
      //display: none !important;
    }
  }

  &:hover {
    .react-phone-number-input__country {
      .react-phone-number-input__icon {
        .international-icon {
          background-image: url('/images/icons/phone_active.svg');
        }
      }
    }
    &.is-invalid {
      .react-phone-number-input__input {
        border-color: $danger;
      }
    }
  }

  &.is-invalid {
    .react-phone-number-input__input {
      border-color: $danger;
    }
  }
}

.payment-radio-group {
  padding-inline: 0 !important;
  .custom-radio {
    align-items: center;
    padding-left: 0;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &:hover {
      border-color: $brown;
      box-shadow: $default-shadow;
    }

    .custom-control-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: $black;
      border: 1px solid rgba($dark-grey, 0.25);
      background-color: $light-4;
      padding: 20px 20px 20px 40px;
      cursor: pointer;

      &:before,
      &:after {
        // top: calc(50% - 8px);
        top: 3px;
        left: 20px;
      }

      .payment-icon {
        width: 27px;
        height: auto;
        margin: 0 10px;
      }

      .payment-action {
        color: $dark-grey;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  &_transparent {
    margin-bottom: 20px;

    .custom-radio {
      padding-bottom: 30px;
      border-bottom: 1px solid rgba($dark-grey, 0.25);

      &:hover {
        border-bottom: 1px solid rgba($dark-grey, 0.25);
        box-shadow: none;
      }

      .custom-control-label {
        padding: 0;
        padding-left: 30px;
        border: none;
        background: transparent;

        &:before,
        &:after {
          left: 0;
        }
      }
    }
  }

  &_no-border {
    .custom-radio {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;

      &:hover {
        border-bottom: 0;
      }
    }
  }
}

.payment-select-label {
  .displayName {
    display: inline-table;
    line-height: 1.5;
    .fontItalic {
      font-style: italic;
      font-size: 12px;
    }
  }
  .payment-icon {
    width: 27px;
    height: auto;
    margin: 0 10px 0 0;
  }
  span {
    font-size: 14px;
    vertical-align: middle;
  }
}
.payment-link-label {
  display: flex;
  align-items: flex-start;
  margin-left: 0.8rem;
  .displayName {
    display: inline-table;
    line-height: 1.5;
    margin-top: 0.125rem;
    .fontItalic {
      font-style: italic;
      font-size: 12px;
    }
  }
  .payment-icon {
    width: 1.25rem;
    height: 1.5625rem;
    margin: 0 0.5rem;
  }
  span {
    font-size: 14px;
    vertical-align: middle;
  }
}
.montly-billing-label {
  // display: flex;
  align-items: flex-start;
  margin-left: 1.6rem;
  margin-bottom: 1rem;
  .displayName {
    display: inline-table;
    line-height: 1.5;
    // margin-top: 0.125rem;
    margin-bottom: 1rem;
    // .fontItalic {
    //   font-style: italic;
    //   font-size: 12px;
    // }
  }
  span {
    font-size: 14px;
    vertical-align: middle;
  }
}

.partner-logo-field {
  width: 120px;
  text-align: center;
  text-transform: none;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;

  .company-logo {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($dark-grey, 0.5);
    border-radius: 50%;
    font-size: 45px;
    line-height: 1;
    color: $white;
    margin-bottom: 25px;
    text-transform: uppercase;
    padding: 4px 3px 0 3px;
    &.isCompanyLogoPage {
      padding: 0 !important;
    }
  }

  img.company-logo {
    object-fit: cover;
    opacity: 1;
  }

  &:hover {
    .action-btn {
      text-decoration: underline;
    }
  }
}
.company-logo-field {
  text-align: center;
  text-transform: none;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;

  .company-logo {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($dark-grey, 0.5);
    border-radius: 50%;
    font-size: 45px;
    line-height: 1;
    color: $white;
    margin-bottom: 25px;
    text-transform: uppercase;
    padding: 4px 3px 0 3px;
    &.isCompanyLogoPage {
      padding: 0 !important;
    }
  }

  img.company-logo {
    object-fit: cover;
    opacity: 1;
  }

  &:hover {
    .action-btn {
      text-decoration: underline;
    }
  }
}

.pdf-preview {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $brown;
  font-size: 12px;
  img {
    margin-right: 5px;
  }
}

.testCheckBox {
  .custom-checkbox .custom-control-input {
    margin-left: -23px;
    display: block;
    position: absolute;
    z-index: 1;
    opacity: 0;
  }
}
.normalizeText {
  p {
    text-transform: none !important;
  }
  pre {
    text-transform: none !important;
  }
}
.normalizeText-link {
  p {
    text-transform: none !important;
    color: #8b959e;
  }
  a {
    text-transform: none !important;
    color: #8b959e;
  }
}

.max-width-80 {
  max-width: 80%;
}
.white-space-nowrap {
  white-space: nowrap;
}

.react-tel-input .country-list .country,
.react-tel-input .form-control {
  font-family: 'AvenirNext';
}

.react-tel-input .form-control {
  // font-size: 16px;
  // background: $white !important;
  background: $light-2 !important;
}
.react-tel-input {
  .phone-field-row & {
    .form-control {
      width: calc(75% - 4px) !important;
      margin-left: auto !important;
      padding-left: 16px !important;
      // background: $white !important;
      background: $light-3 !important;
    }
  }
  .phone-field-equal & {
    .form-control {
      width: calc(50% - 4px) !important;
      margin-left: auto !important;
      padding-left: 16px !important;
      background: $white !important;
    }
  }

  .phone-field-row & {
    .selected-flag {
      // background-color: $white !important;
      background-color: $light-3 !important;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      width: 100% !important;
      &.open {
        width: calc(25% - 6px) !important;
        &::after {
          top: 32% !important;
          transform: rotate(180deg) !important;
        }
      }
      &.open:before {
        box-shadow: none;
      }

      &:focus:before,
      &.open:before {
        display: none;
      }

      .arrow {
        display: none;
      }
      &::after {
        content: '';
        height: 17px;
        width: 17px;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        background: url('/images/icons/caret-down.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 17px 17px;
      }
    }
  }
  .phone-field-equal & {
    .selected-flag {
      // background-color: $white !important;
      background-color: $light-2 !important;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      width: 100% !important;
      &.open {
        width: calc(50% - 8px) !important;
        &::after {
          top: 32% !important;
          transform: rotate(180deg) !important;
        }
      }
      &.open:before {
        box-shadow: none;
      }

      &:focus:before,
      &.open:before {
        display: none;
      }

      .arrow {
        display: none;
      }
      &::after {
        content: '';
        height: 17px;
        width: 17px;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        background: url('/images/icons/caret-down.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 17px 17px;
      }
    }
  }

  .phone-field-row & {
    .flag-dropdown {
      font-size: 14px;
      width: calc(25% - 6px) !important;
      &.open {
        width: 100% !important;
        .country-list {
          width: calc(100% + 2px);
        }
      }
    }
  }
  .phone-field-equal & {
    .flag-dropdown {
      font-size: 14px;
      width: calc(50% - 6px) !important;
      &.open {
        width: 100% !important;
        .country-list {
          width: 49%;
        }
      }
    }
  }

  .flag-dropdown:hover,
  .flag-dropdown:focus {
    box-shadow: none;
    outline: none;
  }

  .flag-dropdown .selected-flag:hover,
  .flag-dropdown .selected-flag:focus {
    outline: none;
    border: 1px solid #0c0c0c;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: #fbfbfb;
  }
}

.change-request-section {
  .react-tel-input {
    .phone-field-row & {
      .form-control {
        width: calc(50% - 4px) !important;
        margin-left: auto !important;
        padding-left: 16px !important;
        background: $white !important;
      }
    }
    .phone-field-equal & {
      .form-control {
        width: calc(50% - 4px) !important;
        margin-left: auto !important;
        padding-left: 16px !important;
        background: $white !important;
      }
    }

    .selected-flag {
      background-color: #fbfbfb !important;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      width: 100% !important;
      &.open {
        width: calc(50% - 8px) !important;
        &::after {
          top: 32% !important;
          transform: rotate(180deg) !important;
        }
      }
      &.open:before {
        box-shadow: none;
      }

      &:focus:before,
      &.open:before {
        display: none;
      }

      .arrow {
        display: none;
      }
      &::after {
        content: '';
        height: 17px;
        width: 17px;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        background: url('/images/icons/caret-down.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 17px 17px;
      }
    }

    .flag-dropdown {
      font-size: 14px;
      width: calc(50% - 8px) !important;
      &.open {
        width: 100% !important;
        .country-list {
          width: calc(100% + 2px);
        }
      }
    }

    .flag-dropdown:hover,
    .flag-dropdown:focus {
      box-shadow: none;
      outline: none;
    }

    .flag-dropdown .selected-flag:hover,
    .flag-dropdown .selected-flag:focus {
      outline: none;
      border: 1px solid #0c0c0c;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      background-color: #fbfbfb;
    }
  }
}

.partner-section {
  .react-tel-input {
    .phone-field-row & {
      .form-control {
        width: calc(73% - 4px) !important;
        margin-left: auto !important;
        padding-left: 16px !important;
        background: $white !important;
      }
    }

    .selected-flag {
      background-color: #fbfbfb !important;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      width: 100% !important;
      &.open {
        width: calc(25% - 6px) !important;
        &::after {
          top: 32% !important;
          transform: rotate(180deg) !important;
        }
      }
      &.open:before {
        box-shadow: none;
      }

      &:focus:before,
      &.open:before {
        display: none;
      }

      .arrow {
        display: none;
      }
      &::after {
        content: '';
        height: 17px;
        width: 17px;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        background: url('/images/icons/caret-down.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 17px 17px;
      }
    }

    .flag-dropdown {
      font-size: 14px;
      width: calc(28% - 8px) !important;
      &.open {
        width: 100% !important;
        .country-list {
          width: calc(100% + 2px);
        }
      }
    }

    .flag-dropdown:hover,
    .flag-dropdown:focus {
      box-shadow: none;
      outline: none;
    }

    .flag-dropdown .selected-flag:hover,
    .flag-dropdown .selected-flag:focus {
      outline: none;
      border: 1px solid #0c0c0c;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      background-color: #fbfbfb;
    }
  }
}

.notes-expandable {
  display: block;
  min-height: 160px;
  border: 0.5px solid transparent;
  background-color: $light-2;
  border-radius: 2px;
  box-shadow: $default-shadow;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  &:hover,
  &:focus {
    outline: none;
    border-color: $black;
    box-shadow: $default-shadow;
    background-color: $light-2;
  }
  &:focus {
    color: #495057 !important;
  }
}

.notes-expandable-dispatch {
  display: block;
  min-height: 100px;
  border: 0.5px solid transparent;
  background-color: $light-2;
  border-radius: 2px;
  box-shadow: $default-shadow;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  &:hover,
  &:focus {
    outline: none;
    border-color: $black;
    box-shadow: $default-shadow;
    background-color: $light-2;
  }
  &:focus {
    color: #495057 !important;
  }
}

.notes-expandable-dispatch-accepted {
  display: block;
  min-height: 100px;
  border: 0.5px solid transparent;
  background-color: rgba($dark-grey, 0.1);
  border-radius: 2px;
  box-shadow: $default-shadow;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
}

.notes-expandable-partner {
  display: block;
  min-height: 100px;
  box-shadow: none !important;
  border: 1px solid rgba($dark-grey, 0.2) !important;
  background-color: $white;
  border-radius: 2px;
  padding: 0.375rem 0.75rem;
  font-size: 15px;
  font-weight: 400;
  &:hover,
  &:focus {
    outline: none;
    border: none;
  }
  &:focus {
    color: #495057 !important;
  }
}

.notes-expandable-client {
  display: block;
  min-height: 100px;
  box-shadow: none !important;
  padding: 10px;
  background-color: #fbfbfb;
  color: #0c0c0c;
  font-size: 14px;
  font-weight: 400;
  border: 0.5px solid transparent;
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;

  &:hover,
  &:focus,
  &:active {
    border-color: #0c0c0c;
    outline: none;
  }
}

[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: rgba($black, 0.33);
}

.notes-expandable-chauffeur {
  display: block;
  border: 0.5px solid transparent;
  background-color: $light-2;
  border-radius: 2px;
  box-shadow: $default-shadow;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  &:hover,
  &:focus {
    outline: none;
    border-color: $black;
    box-shadow: $default-shadow;
    background-color: $light-2;
  }
  &:focus {
    color: #495057 !important;
  }
  // diff width values for cross-browser compatibility
  width: -webkit-fill-available;
  width: -moz-available;
  max-width: 97%;
}
.payments-search-bar {
  margin-top: 0.5rem !important;
  margin-bottom: 2.5rem !important;
  padding-left: 0px !important;
}

.input-group {
  background-color: $light-2 !important;
  box-shadow: $default-shadow !important;
  .input-group-prepend {
    border-right: transparent !important;
    .input-group-text {
      border: 0.5px solid transparent;
      background-color: $light-2 !important;
      border-radius: 2px;
      border-right: transparent !important;
    }
  }
  input.form-control {
    box-shadow: none !important;
    border-left: transparent !important;
    padding-left: 0px !important;
    background-color: $light-3 !important;
    &:focus,
    &:hover {
      border-radius: 0px !important;
      outline: none !important;
      border-color: transparent !important;
      border-left: transparent !important;
      background-color: $light-2;
    }
  }
  .form-control {
    border-left: transparent !important;
    &:focus,
    &:hover {
      border-radius: 0px !important;
      outline: none !important;
      border-color: transparent !important;
      // border-left: transparent !important;
      background-color: $light-2;
      // box-shadow: $default-shadow !important;
    }
  }
  &:focus,
  &:hover {
    border: 0.5px solid transparent;
    background-color: #fbfbfb !important;
    border-radius: 1px;
    border-color: $black !important;
  }
}

.newPayments-UI {
  margin-bottom: 20px;

  .custom-radio {
    padding-bottom: 20px !important;
    border-bottom: 1px solid rgba($dark-grey, 0.25);

    &:hover {
      border-bottom: 1px solid rgba($dark-grey, 0.25);
      box-shadow: none;
    }

    .custom-control-label {
      padding: 0;
      padding-left: 30px;
      border: none;
      background: transparent;

      &:before,
      &:after {
        left: 0;
      }
    }
  }
}
.newPayments-UI-one-card {
  margin-top: 1.1rem;
  margin-bottom: 20px;
}
.partner-search-bar {
  height: 50px !important;
}
.round-input-group {
  box-shadow: none !important;
  border: 1.5px solid rgba($dark-grey, 0.2);
  border-radius: 20px !important;

  &:hover,
  &:focus,
  &:active,
  &:focus-within {
    border-radius: 20px !important;
    border: 1px solid rgba($black, 1);
    border-color: rgba($black, 1) !important;
  }
  input.form-control {
    height: 42px !important;
    border-radius: 20px !important;
    &:hover,
    &:focus,
    &:active {
      border-radius: 20px !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
.booking-icon-field {
  &:hover,
  &:focus,
  &:active,
  &:focus-within {
    border: 1px solid rgba($dark-grey, 0.2);
  }
}
.extend-calender {
  .react-datepicker-popper .custom-datepicker-calendar {
    width: 125% !important;
  }
  .custom-datepicker-calendar .react-datepicker__navigation--previous {
    left: 30px;
    top: 23px;
  }
  .custom-datepicker-calendar .react-datepicker__navigation--next {
    right: 30px;
    top: 23px;
  }
}
.extend-calender-payout {
  .react-datepicker-popper .custom-datepicker-calendar {
    // width: 125% !important;
    // right: 50px;
  }
  .custom-datepicker-calendar .react-datepicker__navigation--previous {
    left: 30px;
    top: 23px;
  }
  .custom-datepicker-calendar .react-datepicker__navigation--next {
    right: 30px;
    top: 23px;
  }
}

.round-date-group {
  border-radius: 10px;
  input.custom-datepicker-control {
    background-image: url('/images/icons/calendar.png');
    background-size: 17px 17px;
    background-position: center right 13px !important;
    background-repeat: no-repeat;
    padding-right: 30px;
    color: rgba($black, 0.3);
    &.active {
      background-image: url('/images/icons/calendar_active.png');
      color: $black;
    }
  }
  input.form-control {
    box-shadow: none !important;
    border: 1.5px solid rgba($dark-grey, 0.2);
    border-radius: 20px !important;
    height: 45px !important;
    &:hover,
    &:focus,
    &:active {
      border-radius: 20px !important;
      border: 1px solid rgba($black, 1);
      border-color: rgba($black, 1) !important;
    }
  }
  .react-datepicker-popper .custom-datepicker-calendar {
    .react-datepicker__navigation--previous {
      left: 35px;
    }
    .react-datepicker__navigation--next {
      right: 35px;
    }
    .react-datepicker__month-container {
      background-color: $white;
      .react-datepicker__header {
        background-color: $white;
        margin: 0 !important;
      }
      .react-datepicker__month {
        margin: 0 !important;
        margin-bottom: 0.4rem !important;
      }
    }
  }
}
.time-zone-container {
  .time-zone-select__control {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
    border: 0px solid transparent;
    border-radius: 2px !important;
    height: 50px !important;
    font-size: 14px;
    background-color: #fbfbfb;
    &:hover,
    &:focus,
    &:active,
    &:focus-within {
      border-radius: 2px !important;
      border: 0.5px solid transparent;
      border-color: $black !important;
    }
  }
  .time-zone-select__indicator-separator {
    width: 0px !important;
    background-color: none;
  }
  .time-zone-select__dropdown-indicator {
    padding: 0px 5px !important;
  }
  // .css-tj5bde-Svg {
  //   width: 17px;
  //   height: 17px;
  //   stroke: black;
  //   fill: black;
  //   stroke-opacity: 0.5;
  // }
  .time-zone-select__indicators {
    width: 24px;
  }
}
.round-select-group {
  .react-select-container .react-select__control {
    box-shadow: none !important;
    border: 1.5px solid rgba($dark-grey, 0.2);
    border-radius: 20px !important;
    height: 46px !important;
    &:hover,
    &:focus,
    &:active,
    &:focus-within {
      border-radius: 20px !important;
      border: 1px solid rgba($black, 1);
      border-color: rgba($black, 1) !important;
    }
  }
}
.round-input-span {
  border-radius: 20px !important;
}

.payout-radio {
  .custom-control-label {
    flex-grow: 1;
    padding-top: 2px;
    cursor: pointer;
    text-transform: none;
    letter-spacing: initial;
    &:before,
    &:after {
      top: 13px;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }

    &:after {
      background-size: 15px 15px;
    }
  }
}

.payout-radio-label {
  font-size: 15px;
  display: inline-table;
  line-height: 1.5;
  font-weight: 500;
  margin-bottom: 10px;
}
.payout-radio-info-value {
  font-size: 14px;
}
.payout-radio-info {
  font-size: 14px;
  display: inline-table;
  line-height: 1.5;
  color: $dark-grey;
}

.InovuaReactDataGrid__row:not(.InovuaReactDataGrid__row--virtualize-columns) {
  position: sticky !important;
}

.height-add {
  height: 300px !important;
  min-height: 300px !important;

  .InovuaReactDataGrid__cell.InovuaReactDataGrid__cell {
    height: 300px !important;
    min-height: 300px !important;
  }
}

.height-add-vip {
  height: 150px !important;
  min-height: 150px !important;

  .InovuaReactDataGrid__cell.InovuaReactDataGrid__cell {
    height: 150px !important;
    min-height: 150px !important;
  }
}

.payout-radio-label-partner {
  font-size: 15px;
  display: inline-table;
  line-height: 1.5;
  margin-left: 0.3rem;
  margin-top: 0.5rem;
}

.bank-info-partner {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: 0.3rem;
  padding: 1rem 7rem 1rem 1rem;
  background-color: rgba($dark-grey, 0.05);
  width: fit-content;
}

.badge.badge-outlined.badge-paid-partner {
  font-size: 10px;
  border-color: $black;
  color: $black;
  padding: 6px 10px 6px 10px !important;
  background-color: rgba($black, 0.1);
}
.badge.badge-outlined.badge-not_paid-partner {
  font-size: 10px;
  border-color: $warning;
  color: $warning;
  padding: 6px 10px 6px 10px !important;
  background-color: rgba($warning, 0.1);
}

.partner-payout-card {
  border-radius: 3px !important;
  border: 1px solid rgba($dark-grey, 0.2);
  background-color: $light-2;
  // padding: 2rem 0rem 2rem 0rem;
  font-size: 15px;
  margin-top: 1em;
  width: calc(100vw - 80%);
  margin-top: 0em !important;
}

.partner-payout-details {
  display: block;
  padding: 0.8rem 2rem 0rem 2rem;
  .expanded {
    .caret-black {
      position: absolute;
      top: calc(50% - 13px);
      width: 49px;
      height: 20px;
    }
  }
}

.bank-info-client {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  // margin-left: 0.3rem;
  // padding: 1.5rem 7rem 1.5rem 1.5rem;
  padding: 18px;
  background-color: rgba($dark-grey, 0.05);
  width: fit-content;
}

.client-payout-card {
  border-radius: 3px !important;
  border: 1px solid rgba($dark-grey, 0.2);
  // background-color: $light-2;
  // padding: 2rem 0rem 2rem 0rem;
  font-size: 15px;
  margin-top: 1em;
  width: calc(95vw - 80%);
  margin-top: 0em !important;
}

.btn-help {
  font-size: 15px !important;
  color: $brown;
  cursor: pointer;
  &:hover {
    color: $brown-active !important;
  }
}

.payout-text-black {
  color: $black;
  font-size: 14px;
}
.payout-text-gold {
  color: $brown;
  font-size: 14px;
}

.payout-border-bottom {
  border-bottom: 1px solid rgba($dark-grey, 0.2);
}

.newPayments-label {
  margin-left: 0.9rem;
  margin-bottom: 2rem;
  margin-top: 0.3rem;
}
.newPayments-label-one-card {
  margin-left: 0.9rem;
  margin-top: 0.3rem;
  padding-bottom: 20px !important;
  border-bottom: 1px solid rgba(139, 149, 158, 0.25);
  margin-bottom: 18px;
}
.newPayments-label-monthly {
  margin-left: 0.9rem;
  margin-top: 0.3rem;
  padding-bottom: 20px !important;
  border-bottom: 1px solid rgba(139, 149, 158, 0.25);
  margin-bottom: 18px;
}

.grey-field-input {
  input,
  // label,
  .react-select__control,
  .react-tel-input.form-control {
    background-color: $light-3 !important;
  }
}
.grey-field-input-l3 {
  input,
  label,
  .react-select__control,
  .react-tel-input.form-control {
    background-color: $light-3 !important;
  }
}
.input.form-control.grey-field-input {
  background-color: $light-3 !important;
}
.input.form-control.grey-field-input-l3 {
  background-color: $light-3 !important;
}
.react-currencySelect-container.grey-field-input {
  background-color: $light-3 !important;
}
.react-currencySelect-container.grey-field-input-l3 {
  background-color: $light-3 !important;
}

.custom-white-background {
  input,
  .react-select__control,
  .custom-file-label,
  .react-tel-input .form-control {
    background-color: $white !important;
  }
  .custom-file-label:hover {
    background-color: $white !important;
  }
}
.react-currencySelect-container {
  &.custom-white-background {
    background-color: $white !important;
  }
}
.partner-grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.form-select-icon {
  //This was not there
  border-radius: 2px;
  border-color: $black !important;
  border: 0.5px solid transparent;

  //this is for form-select
  .input-group {
    flex-wrap: nowrap;
    box-shadow: none !important;
    // border-radius: 1px;
    // border: 1px solid $black !important;
    border-radius: 2px;
    border-color: $black !important;
    border: 0.5px solid transparent;

    .input-group-text {
      color: rgba(12, 12, 12, 0.3);
    }

    .black-text {
      color: $black;
    }
  }

  // .input-group .react-select-container .react-select__menu {
  //   width: 100% !important;
  //   position: absolute !important;
  // }

  //this is for form-field
  // &.input-group {
  //   box-shadow: none !important;
  //   border-radius: 1px;
  //   border: 0.5px solid rgba($dark-grey, 0.25);
  //   &:hover {
  //     border-radius: 1px;
  //     border-color: $black !important;
  //     border: 0.5px solid transparent;
  //   }
  // }

  // &.active-tab {
  //   border-radius: 1px;
  //   border-color: $black !important;
  //   border: 0.5px solid transparent;
  //   .input-group {
  //     border-radius: 1px;
  //     border-color: $black !important;
  //     border: 0.5px solid transparent;
  //   }

  //   input {
  //     border-radius: 1px;
  //     border-color: $black !important;
  //     border: 0.5px solid transparent;
  //     &:focus {
  //       border-radius: 1px;
  //       border-color: $black !important;
  //       border: 0.5px solid transparent;
  //     }
  //   }
  // }

  .react-select__control {
    border-radius: 0px !important;
    outline: none !important;
    border-color: transparent !important;
    border-left: transparent !important;
    box-shadow: none;
    &:hover {
      cursor: pointer;
    }
  }

  .react-select__placeholder {
    right: 0;
    padding-right: 0.5em;
  }

  .react-select__single-value {
    text-align: right;
    padding-right: 0.5em;
  }
  .react-select__option {
    font-size: 14px !important;
  }
}

.form-label-div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .form-label-icon {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
  .form-label-counter {
    color: black;
    text-transform: none;
    font-size: 15px !important;
    margin-bottom: 0;
  }
  .form-counter-tooltip {
    width: 14px;
    margin-left: 6px;
    padding-bottom: 3px;
    cursor: pointer;
  }
}

.form-field-counter {
  position: relative;
  display: inline-block;

  .form-field-minus {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    img {
      width: 13px;
      padding-bottom: 2px;
    }
  }

  .form-field {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
  }

  .form-field-plus {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    img {
      width: 13px;
      padding-bottom: 2px;
    }
  }
}
.company-logo-white {
  background-color: transparent;
}

.automation-time-select {
  .react-select__control {
    box-shadow: none;
    height: auto;
  }
}
