.client-chat-layout {
  background: $white;
}
.client-chat-layout main {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.client-chat-container {
  height: 100%;
  display: grid;
  grid-template-columns: 420px 1fr;
  grid-gap: 10px;
  align-items: stretch;
  padding: 0 20px 0;
  align-self: center;
  border-top: none;
  width: 100%;

  .user-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;

    &.user-online {
      border: 1px solid $success;
    }

    img {
      display: block;
      //width: 100%;
      //height: 100%;
      width: 34px;
      height: 34px;
      object-fit: cover;
      object-position: center center;
      &.rolzo-avatar {
        width: 34px;
        height: 34px;
      }
    }
  }

  .client-chat-room {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: calc(100vw - 250px);
    margin: 0 auto;
    border-left: none;
    height: 100%;

    .spinner_wrapper {
      z-index: 1;
    }

    .room-header {
      position: absolute;
      height: 108px;
      width: calc(100vw - 490px);
      top: 0;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 70px;
      border-left: 1px solid rgba($black, 0.05);
      font-size: 14px;
      font-weight: 500;
      color: $brown;

      a:hover {
        text-decoration: none;
      }

      .user-avatar {
        margin-right: 10px;
      }
    }

    .client-messages-list {
      height: 100%;
      padding: 20px 20px 0 20px;
      max-width: 1000px;
      flex: 1;
      overflow: auto;
      display: flex;
      flex-direction: column-reverse;

      .ScrollbarsCustom-Track {
        background-color: $white !important;
        opacity: 0;
        transition: opacity 200ms ease;
        transform: translateY(0px);
        width: 8px !important;
        padding-left: 2px;

        .ScrollbarsCustom-Thumb {
          width: 100%;
          background-color: rgba(120, 128, 134, 0.5) !important;
        }
      }

      &:hover {
        .ScrollbarsCustom-Track {
          opacity: 1;
        }
      }
      .ScrollbarsCustom-Content {
        padding-right: 10px !important;
        padding-top: 10px !important;
        padding-bottom: 25px !important;
      }

      .date-line {
        width: 100%;
        border-top: 1px solid rgba($black, 0.05);
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        padding-top: 5px;
        div {
          font-size: 12px;
          color: #8b959e;
          // text-transform: uppercase;
          background: $white;
          margin-top: -13px;
          width: 80px;
          text-align: center;
          white-space: nowrap;
        }
      }
      .message {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 25px;
        padding-left: 5px;

        .user-avatar {
          order: 1;
          margin-right: 10px;
          flex-shrink: 0;
          border: 1px solid rgba($black, 0.1);
          object-fit: cover;
          object-position: center center;
        }

        .text-wrap {
          order: 2;
          max-width: 65%;
          background-color: $white;
          border-radius: 2px;
          box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
          padding: 12px;

          .text {
            margin-bottom: 4px;
            display: inline-block;
            width: 100%;
            word-break: break-word;
            font-size: 15px;
          }
          .message-info {
            font-size: 12px;
            color: rgba($dark, 0.5);
            text-align: right;
            display: inline;
          }
          .message-info-booked {
            font-size: 12px;
            color: rgba($dark, 0.5);
            float: right;
          }
          .download-link {
            color: #a79779;
            font-size: 10px;
            display: block;
            text-align: start;
            .pdf-icon {
              width: 11px;
              height: 11px;
              margin-right: 7px;
            }
            &:hover {
              text-decoration: none;
            }
          }
        }
        &.is-current {
          .text-wrap {
            background-color: rgba(#8b959e, 0.1);
            box-shadow: none;
          }
        }
        &.is-typing {
          align-items: center;
          visibility: visible;

          .user-avatar {
            order: 0;
          }
        }

        &.is-current {
          justify-content: flex-end;

          .user-avatar {
            order: 2;
            margin-right: 0;
            margin-left: 15px;
          }

          .text-wrap {
            order: 1;
          }
        }
        .typer-name {
          margin-top: 3%;
          margin-bottom: -13%;
          color: #8b959e;
        }
        .typer-name-desktop {
          margin-bottom: -10%;
          color: #8b959e;
        }
        &.hidden {
          visibility: hidden;
        }
        &.displaynone {
          opacity: 0;
        }
      }
    }
    .client-message-input-block {
      position: fixed;
      height: inherit;
      // max-height: 150px;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-top: 5px;
      //display: flex;
      //justify-content: center;
      border-top: 1px solid rgba($dark, 0.3);
      .chat-footer-container {
        max-width: 380px;
        position: fixed;
        bottom: 0;

        .empty-collect-files {
          visibility: hidden;
          padding: 0 !important;
          margin: 0 !important;
        }
        .collected-files {
          display: block;
          background: white;
          width: 380px;
          border-top: 1px solid rgba(139, 149, 158, 0.3);
          margin-left: 0;
          padding: 10px 10px 5px 10px;

          .file-preview {
            justify-content: normal;
            padding: 3px 0;

            .image-preview-label {
              max-width: unset;
              width: 100%;
            }
          }
        }
      }
    }
    .message-input-toolbar {
      //flex-shrink: 0;
      //padding: 0 155px 40px 0;
      display: flex;
      //align-items: center;
      //position: fixed;
      //bottom: 110px;
      //height: 40px;
      //width: calc(100vw - 550px);
      .form-group {
        margin: 0;
      }
      .custom-file-label {
        height: 40px;
        left: 26px;

        .placeholder-icon {
          height: auto;
          width: 14px;
          margin-top: 2px;
        }
      }

      .message-input-wrap {
        position: relative;
        flex-grow: 1;
        height: 100%;
        width: calc(100% - 45px);
        max-width: 660px;
        margin-left: 4px;

        //max-width: calc(100vw - 570px);
        .custom-file,
        .custom-file-label {
          background-color: transparent;
        }
        .toolbar-buttons {
          position: absolute;
          left: 8px;
          top: 10px;
          display: flex;
          align-items: center;

          .toolbar-btn {
            &.car-btn {
              width: 24px;
              height: 24px;
              margin-right: 22px;
              z-index: 1;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        //
        //.form-control,
        //.mention-textarea textarea {
        //  min-height: auto;
        //  height: 80px !important;
        //  border: none;
        //  outline: none;
        //  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
        //  padding-left: 150px;
        //  position: relative;
        //  //max-width: calc(100vw - 570px);
        //  //max-height: 80px;
        //  overflow-y: auto !important;
        //  background: $white !important;
        //}
        //
        //.mention-textarea textarea {
        //  padding-left: 46px;
        //  padding-top: 29px;
        //}
        //
        //.mention-textarea__suggestions {
        //  bottom: 0;
        //  top: inherit !important;
        //  .mention-textarea__suggestions__list {
        //    background-color: $white;
        //    border: 1px solid $brown;
        //    border-radius: 2px;
        //    .mention-textarea__suggestions__item {
        //      padding: 5px 10px;
        //      width: 300px;
        //      &:hover,
        //      &:focus {
        //        background: $brown;
        //      }
        //    }
        //  }
        //}
      }

      &.client-toolbar {
        bottom: 110px;
        width: 100%;
        max-width: 1000px;
        .message-input-wrap {
          //max-width: calc(100vw - 400px);
          textarea {
            padding: 9px 0 0 46px !important;
            font-size: 15px !important;
          }

          textarea::placeholder {
            color: #000000;
            opacity: 0.4;
          }

          textarea:-ms-input-placeholder {
            color: #000000;
            opacity: 0.4;
          }

          textarea::-ms-input-placeholder {
            color: #000000;
            opacity: 0.4;
          }
        }
        .custom-file-label {
          left: 1px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        //.form-control,
        //.mention-textarea textarea {
        //  max-width: 1000px;
        //  //max-width: calc(100vw - 380px);
        //}
      }

      .send-avatar-wrap {
        display: none;
        position: relative;
        height: 100%;
        margin-top: 8px;
        margin-left: 8px;

        .send-avatar {
          top: 0;
          position: absolute;
          width: 36px;
          height: 36px;
          flex-shrink: 0;
          margin: auto;
          border: 1px solid rgba(12, 12, 12, 0.1);
          border-radius: 40px;
          object-position: center center;
          object-fit: cover;

          img {
            width: 20px;
            height: 20px;
            margin-top: 6px;
            margin-left: 7px;
          }
        }
      }
      .send-btn-wrap {
        position: relative;
        height: 100%;
        .send-btn {
          top: 0;
          position: absolute;
          width: 30px;
          height: 40px;
          margin: 0;
          margin-left: 20px;
          flex-shrink: 0;
          padding-top: 9px;

          &:hover {
            opacity: 0.8;
          }
          &:focus {
            box-shadow: none !important;
          }

          img {
            width: 24px;
            height: 20px;
            object-fit: contain;
          }
        }
      }
    }
  }
}

.collected-files {
  width: calc(100% - 80px);
  white-space: nowrap;
  overflow-x: auto;
  //height: 50px;
  align-items: center;
  justify-content: flex-start;
  .form-group {
    margin: 0;
  }
}

.file-preview {
  //height: 45px;
  flex: 0 0 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 3px 0;
  .image-preview-label {
    width: 100%;
    overflow: hidden;
    margin: 0 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
  }
  .removable-image img {
    max-width: 16px !important;
    max-height: 16px !important;
  }
  .remove-file-btn {
    display: block;
    color: #a79779;
    &:hover {
      text-decoration: none;
      color: #a79779;
    }
    img {
      width: 10px;
      height: 10px;
    }
  }
}

.vehicle-checkbox {
  .custom-control-label:after {
    border-radius: 2px;
    background-color: #fbfbfb;
    border: 1px solid #8b959e;
    background-size: 8px 11px;
    background-position: center center;
    height: 20px;
    width: 20px;
  }
}

.coverage-checkbox {
  z-index: 0;
  .custom-control-label:after {
    border-radius: 2px;
    background-color: #fbfbfb;
    border: 1px solid #8b959e;
    background-size: 5px 8px;
    background-position: center center;
    height: 15px;
    width: 15px;
    z-index: 0;
  }
  .custom-control-input:checked ~ .custom-control-label:after {
    border-color: black;
    background-size: 9px 11px;
  }
}

.is-editable-checkbox {
  float: right;
  margin-top: 5px;
}
.is-editable-checkbox-admin {
  float: right;
  margin-top: 30px;
}
.vehicle-wrap {
  order: 2;
  color: rgba($dark-4, 0.6);
  font-size: 12px;
}

.support-chat {
  width: 100%;

  .client-chat-container {
    padding: 0px !important;
    border-top: unset;
    display: unset;
    grid-template-columns: unset;
    grid-gap: unset;
    align-items: unset;
    padding: unset;
  }

  .client-messages-list {
    padding: 0px !important;
    height: calc(100vh - 113px) !important;
  }

  .client-chat-room {
    width: 100% !important;
  }

  .client-message-input-block {
    position: unset !important;
    height: 52px !important;
  }

  .chat-vehicle-card {
    max-width: 360px !important;
  }

  .send-avatar-wrap {
    width: 45px !important;
    height: 47px !important;
  }

  .send-btn-wrap {
    width: 40px !important;
    height: 45px !important;
  }

  .send-btn {
    margin-left: 6px !important;
  }

  .ScrollbarsCustom-Scroller::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .ScrollbarsCustom-Scroller::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
  }

  .ScrollbarsCustom-Scroller::-webkit-scrollbar-thumb {
    background-color: rgba(120, 128, 134, 0.5) !important;
    border-radius: 10px;
  }

  .ScrollbarsCustom-Content::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .ScrollbarsCustom-Content::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
  }

  .ScrollbarsCustom-Content::-webkit-scrollbar-thumb {
    background-color: rgba(120, 128, 134, 0.5) !important;
    border-radius: 10px;
  }
}

.message-info-bottom {
  .message-info {
    font-size: 12px;
    color: rgba($dark, 0.5);
    text-align: right;
    display: inline;
  }
  .message-info-booked {
    font-size: 12px;
    color: rgba($black, 1);
    // float: right;
    text-align: right;
    img {
      width: 10px;
      vertical-align: text-top;
    }
  }
}
.message-info-bottom-chat {
  .message-info {
    font-size: 12px;
    color: rgba($dark, 0.5);
    text-align: right;
    display: inline;
  }
  .message-info-booked {
    font-size: 12px;
    color: rgba($black, 1);
    // float: right;
    text-align: right;
    img {
      width: 10px;
      vertical-align: text-top;
    }
  }
}

.client-messages-list .message-info-bottom {
  max-width: 300px;
}
.client-messages-list .message-info-bottom-chat {
  max-width: 360px;
}
.client-chat {
  max-width: 360px;
}
.select-vehicle-chat {
  height: 40px;
  min-width: 360px !important;
  font-size: 14px;
  margin-bottom: 5px !important;
}
.care-info-column .quote-right-top-section {
  padding-top: 32px;
}

.app-content .sidebar-container .nav-link span {
  padding-top: 8px;
  padding-bottom: 5px;
}

.my-booking-table .data-table,
.all-bookings .data-table {
  tr {
    &:hover {
      td {
        &:first-child {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

.booking-preview-info .booking-event-section {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.booking-preview-info .booking-preview-info-section .chat-list-container {
  flex-grow: 1;
  height: 100% !important;
}

.booking-preview-info .booking-preview-info-section .chat-list-booking {
  flex-grow: 1;
  height: calc(100vh - 17rem) !important;
}

.booking-preview-info
  .booking-event-section
  .input-message-block
  .input-message-area {
  flex-wrap: nowrap !important;
  .input-message-wrap {
    // max-width: calc(100% - 140px);
    max-width: 100%;
  }
}

.client-chat-container .client-chat-room {
  height: calc(100vh - 62px);
  display: flex;
  flex-direction: column;
  .client-messages-list {
    width: 100%;
    flex-grow: 1;
    height: 0% !important;
  }
  .client-message-input-block {
    height: auto !important;
    .chat-footer-container {
      position: unset;
    }
  }
  .textarea-chat-control {
    border: 1px solid rgba(12, 12, 12, 0.1);
    div {
      border: none !important;
    }
  }
}
.client-chat-room-open {
  height: calc(100vh - 125px) !important;
}
.client-chat-room-close {
  height: calc(100vh - 220px) !important;
}

.chat-box-focus {
  @media (max-width: 768px) {
    .sidebar-bottom-container {
      display: none;
    }

    .client-chat-container .client-chat-room .client-message-input-block {
      bottom: 0 !important;
    }
  }
}

.chat-padding__input {
  left: 6px !important;
}
.chat-padding__control {
  width: 102%;
}
