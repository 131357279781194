.small-screen-sign-up-header {
  padding: 12px 15px;
  // height: 80px;
  border-bottom: 1px solid rgba($dark-grey, 0.3);
  h6 {
    font-weight: 500;
  }
}
@media (max-width: 1120px) and (min-width: 770px) {
  .light-tabs-content {
    .booking-col {
      // max-width: 260px;
    }
  }
  .new-booking-row {
    .booking-col:last-child {
      margin-left: 0px;
    }
  }
}
.table-responsive {
  min-height: 200px;
}
@media (min-width: 992px) {
  //.navbar-form {
  //    margin-top: 21px;
  //    margin-bottom: 21px;
  //    padding-left: 5px;
  //    padding-right: 5px;
  //}
  //.navbar-nav > li > .dropdown-menu, .dropdown .dropdown-menu{
  //    @include transform-scale(0);
  //    @include transition($slow-transition-time, $transition-bezier);
  //}
  //.navbar-nav > li.open > .dropdown-menu, .dropdown.open .dropdown-menu{
  //    @include transform-scale(1);
  //    @include transform-origin($dropdown-coordinates);
  //
  //}
  //
  //.navbar-nav > li > .dropdown-menu:before{
  //    border-bottom: 11px solid rgba(0, 0, 0, 0.2);
  //    border-left: 11px solid rgba(0, 0, 0, 0);
  //    border-right: 11px solid rgba(0, 0, 0, 0);
  //    content: "";
  //    display: inline-block;
  //    position: absolute;
  //    left: 12px;
  //    top: -11px;
  //}
  //.navbar-nav > li > .dropdown-menu:after {
  //    border-bottom: 11px solid #FFFFFF;
  //    border-left: 11px solid rgba(0, 0, 0, 0);
  //    border-right: 11px solid rgba(0, 0, 0, 0);
  //    content: "";
  //    display: inline-block;
  //    position: absolute;
  //    left: 12px;
  //    top: -10px;
  //}
  //
  //.navbar-nav.navbar-right > li > .dropdown-menu:before{
  //    left: auto;
  //    right: 12px;
  //}
  //
  //.navbar-nav.navbar-right > li > .dropdown-menu:after{
  //    left: auto;
  //    right: 12px;
  //}
  //
  //.footer:not(.footer-big){
  //    nav > ul{
  //       li:first-child{
  //         margin-left: 0;
  //       }
  //    }
  //}

  body > .navbar-collapse.collapse {
    display: none !important;
  }

  //.card{
  //    form{
  //        [class*="col-"]{
  //            padding: 6px;
  //        }
  //        [class*="col-"]:first-child{
  //            padding-left: 15px;
  //        }
  //        [class*="col-"]:last-child{
  //            padding-right: 15px;
  //        }
  //    }
  //}
}

/*          Changes for small display      */

@media (max-width: 768px) {
  .mobile-nav-icons {
    display: flex !important;
  }
  .tandc-card {
    margin: 6.5px 0;
    padding: 30px;
    border-radius: 2px;
    border: 1.5px solid rgba($dark-grey, 0.1);
    overflow: hidden;
    width: 500px;
  }
  .tandc-rental-card {
    margin: 6.5px 0;
    padding: 30px;
    border-radius: 2px;
    border: 1.5px solid rgba($dark-grey, 0.1);
    overflow: hidden;
    width: 485px;
  }
  .client-chat {
    max-width: 100%;
  }
  .client-messages-list .vehicle-card .vehicle-img-wrap .vehicle-img {
    height: 102px;
    object-fit: contain;
  }
  .client-messages-list .message-info-bottom {
    max-width: 100%;
  }
  .client-messages-list .message-info-bottom-chat {
    max-width: 100%;
  }
  .page-wrap {
    width: 100%;

    .scrollable-page-content {
      height: 100vh !important;
      overflow: auto;

      .custom-tabs-nav.light-tabs .nav-link {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .contain-responsive-button.scrollable-page-content {
      padding-bottom: 75px;
    }

    .nested-layout.reset-password-layout {
      h4 {
        white-space: unset !important;
        line-height: 32px;
        font-size: 16px;
        font-weight: 400;
        padding: 4px;
      }

      .navbar {
        height: fit-content;
      }
      header {
        font-size: 16px;
      }
      .layout-btn.back-btn {
        left: 0;
        top: -25px !important;
      }

      .layout-btn.light-close-btn {
        right: 20px;
        width: 30px;
        top: -10px;
      }

      .page-title {
        height: fit-content;
        &.reset-passowrd-page-title {
          padding-top: 0 !important;
        }
        font-size: 16px !important;
        font-weight: 500 !important;
        padding-top: 13px !important;
        width: 100vw;
        text-align: center;
        padding-left: 0;
        padding-right: 20px;
      }
    }

    main {
      //padding: 75px 6% 60px !important;
      //padding: 20px 6% 0px !important;
      padding: 35px 15px !important;
      padding-bottom: 0 !important;
      .search-form {
        display: none;
      }

      .sub-header {
        font-size: 22px;
        font-weight: 400;
      }

      .profile-link {
        margin-bottom: 10px;
      }

      .profile-link .profile-info p.header {
        font-size: 16px;
        font-weight: 600;
      }

      .my-bookings-table {
        margin-top: -20px;

        .title-cat {
          font-size: 16px;
          font-weight: 600;
        }
        @media (max-width: 992px) {
          font-size: 15px;
        }

        .sub-cat {
          font-size: 15px !important;
          font-weight: 400 !important;
        }
        .badge {
          font-size: 14px !important;
          font-weight: 400 !important;
        }

        .table-responsive .data-table tr td:first-child {
          padding-right: 0 !important;
        }

        .table-responsive .data-table tr td:last-child {
          padding-left: 0 !important;
        }
      }

      .team-bookings-table {
        max-width: 1000px !important;
      }

      .small-invoices-table {
        margin-top: -20px;

        .title-cat {
          font-size: 15px;
          font-weight: 600;
        }
        @media (max-width: 992px) {
          font-size: 15px;
        }

        .sub-cat {
          font-size: 15px !important;
          font-weight: 400 !important;
        }
      }

      .data-table {
        thead {
          display: none;
        }

        td {
          p.title-cat {
            font-size: 16px;
          }
          @media (max-width: 992px) {
            p.title-cat {
              font-size: 15px;
            }
          }
          p.sub-cat {
            font-size: 15px;
            color: #8b959e;
          }
          &.w-1 {
            width: 10px;
          }

          .btn-anchor {
            vertical-align: bottom;
          }

          .badge {
            font-size: 15px;
          }

          .users-displayname,
          .users-email {
            font-size: 16px;
            font-weight: 400;
          }

          .users-role {
            font-size: 16px;
            font-weight: 400;
          }

          .dropdown-item {
            font-size: 16px !important;
            font-weight: 400;
          }
        }
      }
      .float-btn-wrap {
        width: 42px;
        height: 42px;
        bottom: 12px;
        right: 24px;
        .chat-btn {
          width: 42px;
          height: 42px;
        }
        .unread-indicator {
          top: 5px;
          right: 2px;
        }
      }

      .booking-form-wrap {
        .booking-empty-search {
          margin-top: 48px;
        }

        .booking-step-text {
          font-size: 22px;
          font-weight: 400;
          margin-bottom: 24px;
          margin-top: 20px;
        }

        .search-fixedheader-wrapper {
          display: flex;
          padding-top: 4px;
          margin-left: -15px;
          margin-bottom: 35px;
          height: 120px;
          width: 100vw;
        }

        .search-fixedheader-back {
          position: absolute;
          left: 0;
          top: 51px;

          img {
            width: 40px;
            height: 40px;
            cursor: pointer;
          }
        }

        .search-fixedheader-text {
          position: absolute;
          left: -40px;
          top: 100px;
          font-size: 22px;
          font-weight: 400;
          padding-left: 55px;
        }

        .sub-header {
          font-size: 22px;
          font-weight: 400;
        }
      }

      .booking-preview-info {
        .booking-preview-info-section {
          padding: 24px;
          .section-header {
            font-size: 22px;
            font-weight: 400;
            // margin-bottom: 0;
          }
          .section-sub-header {
            font-size: 20px;
            font-weight: 400;
            // margin-bottom: 0;
          }

          .cancel-booking {
            color: $red;

            &:hover {
              text-decoration: none;
              color: $red;
            }
          }

          label {
            font-size: 12px;
            font-weight: 500;
          }
          // .coverage-checkbox {
          //   label {
          //     font-size: 12px;
          //     font-weight: 900;
          //   }
          // }

          .coverage-checkbox {
            label {
              font-size: 16px !important;
              // font-weight: 900;
            }
          }

          .form-group p {
            font-size: 16px;
            font-weight: 400;
          }

          // .status-badge {
          //   font-size: 14px !important;
          // }

          .badge {
            font-size: 14px;
            font-weight: 400;
            padding-top: 6px !important;
          }

          .inline-edit-btn {
            font-size: 16px;
            font-weight: 600;
          }

          .payment-section {
            .payment-select-label {
              .displayName {
                display: inline-table;
                line-height: 1.5;
                .fontItalic {
                  font-style: italic;
                  font-size: 12px;
                }
              }
              span {
                display: block;
                margin-left: 10px;
              }
            }
            .payment-link-label {
              .displayName {
                display: inline-table;
                line-height: 1.5;
                margin-top: 0.125rem;
                .fontItalic {
                  font-style: italic;
                  font-size: 12px;
                }
              }
              span {
                display: block;
                margin-left: 10px;
              }
            }
            .montly-billing-label {
              .displayName {
                display: inline-table;
                line-height: 1.5;
                margin-top: 0.125rem;
                .fontItalic {
                  font-style: italic;
                  font-size: 12px;
                }
              }
              span {
                display: block;
                margin-left: 10px;
              }
            }

            .booking-total.justify-content-end {
              justify-content: normal !important;
            }
            .booking-total.justify-content-center {
              justify-content: center;
            }
            .success-payment-block .success-payment-msg {
              text-align: left;
            }
            .success-payment-block .danger-payment-msg {
              text-align: left;
            }
          }

          .chat-list-container .ScrollbarsCustom .ScrollbarsCustom-Track {
            height: calc(100vh - 230px) !important;
          }
          .benefits-body {
            font-size: 15px;
            padding-top: 0.5%;
            padding-left: 1%;
          }
          .benefits-body-exclusive {
            font-size: 16px !important;
            color: $dark-2;
            padding-top: 0% !important;
          }

          .meet-and-assist-features {
            padding-left: 5px;
            padding-top: 2.5em;
            padding-bottom: 2em;

            div {
              background: url('/images/Tick_Icon.png');
              font-size: 16px;
              background-position: top 3px left 0px;
              background-size: 16px 16px;
              background-repeat: no-repeat;
              padding-left: 28px;
              margin-bottom: 4px;
            }
          }
          .meet-and-assist-description {
            font-size: 16px;
            color: $dark-2;
            padding-left: 5px;
            display: block;
            line-height: 27px !important;
          }
        }

        .booking-event-section {
          .message-block .message-header p.date-row {
            font-size: 12px;
            font-weight: 500;
          }

          .message-block .message-content p {
            font-size: 16px;
            font-weight: 400;
          }

          .input-message-block
            .input-message-area
            .input-message-wrap
            textarea {
            box-shadow: unset;
            font-size: 16px !important;
            font-weight: 400 !important;
          }

          .events-div {
            margin-left: 10px;
            padding: 8px;
          }
        }
      }
    }

    .success-msg-modal {
      img {
        width: 40px;
        height: 40px;
        margin-bottom: 15px;
      }

      .success-msg-header {
        font-size: 22px;
        font-weight: 400;
      }

      .success-msg-description {
        font-size: 16px;
        font-weight: 400;
      }

      .btn {
        font-size: 16px !important;
        font-weight: 600;
      }
    }
    .success-msg-verification {
      img {
        width: 40px;
        height: 40px;
        margin-bottom: 15px;
      }

      .success-msg-header {
        font-size: 22px;
        font-weight: 400;
      }

      .success-msg-description {
        font-size: 16px;
        font-weight: 400;
      }

      .btn {
        font-size: 16px !important;
        font-weight: 600;
      }
    }
    .success-msg-quote {
      color: $white;
      z-index: 10;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      top: 0;
      left: 0;
      margin-top: 15%;
      background: $white;

      img {
        display: block;
        width: 150px;
        height: 110px;
        object-fit: cover;
        margin: 0 auto 15px auto;
        margin-bottom: 0;
      }

      .success-msg-header {
        font-size: 20px;
        color: $black;
        text-align: center;
        font-weight: 400;
      }

      .btn {
        text-align: center;
        font-weight: 500;
        font-size: 15px;
      }

      .content-wrap {
        display: flex;
        max-width: 500px;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  .page-with-bottom-menu {
    .scrollable-page-content {
      height: calc(100vh - 80px) !important;
    }
  }

  body {
    position: relative;
  }
  .page-wrap {
    //@include transform-translate-x(0px);
    //@include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    left: 0;

    .navbar-without-stepper {
      height: 60px;
    }

    .client-chat-layout.show-header-line .navbar .navbar-brand header,
    .notifications-loyout.show-header-line .navbar .navbar-brand header {
      margin-top: 90px;
    }

    .navbar {
      border-bottom: 0;
      // height: 180px;
      // border-bottom: 1px solid rgba($dark-grey, 0.2);
      &.mob-extra-padding {
        padding-top: 20px !important;
      }
      &.mob-chat-extra-padding {
        padding-top: 30px !important;
      }
      &.sticky-header {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #fff;
      }
      &.show-border {
        border-bottom: 1px solid #8b959e4d;
      }
      .navbar-brand {
        header {
          margin-top: 130px;
          padding: 0 16px;
          background-color: #ffffff !important;

          .light-close-btn {
            right: 32px;
            top: calc(50% - 32px);

            img {
              width: 36px !important;
            }
          }

          .back-btn {
            left: 6%;
            //top: calc(50% - 34px) !important;
            //vertical-align: baseline;
            img {
              width: 40px;
            }
          }

          .step-back-btn {
            left: 0px;
            top: -77px;
          }

          .company-sm-logo {
            margin-right: 20px;
            font-size: 1rem;
            margin-top: 8px;
            margin-left: 5%;
          }

          .company-logo {
            img {
              width: 45px;
            }
            margin-left: 20px;
            margin-right: 20px;
          }

          .stepper .indicator .info {
            font-size: 12px;
          }
          .edit-client-page-title {
            height: 2rem;
            font-size: 1.75rem !important;
            font-weight: 400;
            margin-bottom: 0.75rem;
          }
          .page-title {
            height: 70px;
            font-size: 28px;
            font-weight: 400;
            // padding-top: 2px;
            display: table-cell;
            vertical-align: top;

            &.stepper-label {
              font-size: 22px;
            }

            p {
              margin-bottom: 0;
            }

            .sub-link {
              font-size: 15px;
              font-weight: 600;
              color: $brown;
              display: block;
              padding-top: 5px;
            }
          }
          .page-title-admin {
            height: 70px;
            font-size: 28px;
            font-weight: 400;
            padding-top: 10px;
            display: table-cell;
            vertical-align: top;

            &.stepper-label {
              font-size: 22px;
            }

            p {
              margin-bottom: 0;
            }

            .sub-link {
              font-size: 15px;
              font-weight: 600;
              color: $brown;
              display: block;
              padding-top: 5px;
            }
          }

          .page-title-booking {
            height: 70px;
            font-size: 28px;
            font-weight: 400;
            padding-top: 30px;
            display: table-cell;
            vertical-align: top;

            &.stepper-label {
              font-size: 22px;
            }

            p {
              margin-bottom: 0;
            }

            .sub-link {
              font-size: 15px;
              font-weight: 600;
              color: $brown;
              display: block;
              padding-top: 5px;
            }
          }

          .stepper {
            width: 90%;
            margin-left: 0;
            .page-title {
              height: 36px;
            }
          }

          .badge {
            position: absolute;
            top: 16px;
            right: 40px;
          }
        }

        .settings-logout-img-wrapper {
          cursor: pointer;
          position: absolute;
          right: 20px;
          top: -45px;

          img {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
    .navbar-partner {
      border-bottom: 0;
      height: 80px !important;

      .navbar-brand {
        header {
          margin-top: 130px;
          padding: 0 0 0 15px;
          background-color: #ffffff;

          .light-close-btn {
            right: 32px;
            top: calc(50% - 32px);

            img {
              width: 36px !important;
            }
          }

          .back-btn {
            left: 6%;
            //top: calc(50% - 34px) !important;
            //vertical-align: baseline;
            img {
              width: 40px !important;
            }
          }

          .step-back-btn {
            left: 0px;
            top: -77px;
          }

          .company-sm-logo {
            margin-right: 20px;
            font-size: 1rem;
            margin-top: 8px;
            margin-left: 5%;
          }

          .company-logo {
            img {
              width: 45px;
            }
            margin-left: 20px;
            margin-right: 20px;
          }

          .stepper .indicator .info {
            font-size: 12px;
          }

          .page-title {
            height: 70px;
            font-size: 28px;
            font-weight: 400;
            padding-top: 2px;
            display: table-cell;
            vertical-align: top;

            &.stepper-label {
              font-size: 22px;
            }

            p {
              margin-bottom: 0;
            }

            .sub-link {
              font-size: 15px;
              font-weight: 600;
              color: $brown;
              display: block;
              padding-top: 5px;
            }
          }
          .page-title-admin {
            height: 70px;
            font-size: 28px;
            font-weight: 400;
            padding-top: 10px;
            display: table-cell;
            vertical-align: top;

            &.stepper-label {
              font-size: 22px;
            }

            p {
              margin-bottom: 0;
            }

            .sub-link {
              font-size: 15px;
              font-weight: 600;
              color: $brown;
              display: block;
              padding-top: 5px;
            }
          }

          .page-title-booking {
            height: 70px;
            font-size: 28px;
            font-weight: 400;
            padding-top: 30px;
            display: table-cell;
            vertical-align: top;

            &.stepper-label {
              font-size: 22px;
            }

            p {
              margin-bottom: 0;
            }

            .sub-link {
              font-size: 15px;
              font-weight: 600;
              color: $brown;
              display: block;
              padding-top: 5px;
            }
          }

          .stepper {
            width: 90%;
            margin-left: 0;
            .page-title {
              height: 36px;
            }
          }

          .badge {
            position: absolute;
            top: 16px;
            right: 40px;
          }
        }

        .settings-logout-img-wrapper {
          cursor: pointer;
          position: absolute;
          right: 20px;
          top: -45px;

          img {
            width: 25px;
            height: 25px;
          }
        }
      }
    }

    .sub-page-with-title {
      .navbar-brand header .page-title {
        font-size: 22px;
        font-weight: 400;
      }

      .sub-header {
        font-size: 22px;
        font-weight: 400;
      }

      .company-logo-field .text-brown {
        font-size: 16px;
      }
    }

    .profile-change-password-link {
      position: absolute;
      top: -37px;
      right: 0px;
      font-size: 15px;
      color: #a79779;
      text-decoration: underline;
    }

    .chatnotification-page-title {
      font-size: 16px !important;
      font-weight: 500 !important;
      text-align: center;
      width: 100vw;
      padding-block: 7px !important;
      height: fit-content !important;
    }
    .account-page-title {
      padding-inline: 16px !important;
      padding-left: 0 !important;
    }
    .chatnotification-page-title {
      .back-btn {
        width: fit-content !important;
        height: fit-content !important;
        img.back-btn-img {
          width: fit-content !important;
        }
      }
    }
    .invoices-page-title {
      flex-direction: column !important;
      align-items: start !important;
      border-bottom: 0 !important;
      height: fit-content !important;
      padding-top: 15px !important;
      .back-btn {
        width: fit-content !important;
        height: fit-content !important;
        img.back-btn-img {
          width: fit-content !important;
        }
      }
      span {
        margin-top: 20px;
      }
    }
    .show-header-line {
      &.booking-detail-layout {
        .navbar {
          height: fit-content;
          .page-title {
            height: 55px;
          }
        }
      }
      .navbar {
        height: 120px;
        margin-bottom: 35px;
        border-bottom: 1px solid rgba($dark, 0.3);

        .navbar-brand header {
          background-color: transparent !important;

          .step-back-btn {
            left: 0px;
            top: -25px;
          }
        }

        .mobile-header-title {
          position: absolute;
          font-size: 16px;
          font-weight: 600;
          text-align: center;
          left: 0;
          width: 100vw;
          pointer-events: none;
          padding-top: 11px;
          // margin-left: 20px;
        }
        .mobile-header-title-activity {
          position: absolute;
          font-size: 16px;
          font-weight: 600;
          text-align: center;
          left: 0;
          width: 100vw;
          pointer-events: none;
          padding-top: 11px;
          // margin-left: 20px;
        }

        .mobile-header-sub-link {
          position: absolute;
          text-decoration: none;
          top: -1px;
          right: 0px;
        }
        .mobile-header-sub-link-indecator {
          img {
            margin-left: calc(50% - 10px);
          }
          .supportbar-unread-indicator {
            top: -30px;
            right: -15px;
          }
        }
        .mobile-header-sub-linktext-with-indecator {
          text-align: center;
          font-size: 10px;
          font-weight: 600;
          color: #8b959e;
          margin-top: -15px;
        }

        .mobile-hader-img-wrapper {
          text-align: center;
          font-size: 20px;

          img {
            width: 20px;
            height: 20px;
          }
        }
        .mobile-hader-img-wrapper-share {
          text-align: center;
          font-size: 20px;

          img {
            width: 25px;
          }
        }

        .mobile-header-sub-linktext {
          text-align: center;
          font-size: 10px;
          font-weight: 600;
          color: #8b959e;
        }

        .mobile-header-sub-link-textonly {
          font-size: 12px;
          color: #d0021b;
          padding-top: 16px;
        }
        .mobile-header-sub-link-textonly-golden {
          color: #a79779 !important;
          font-size: 14px !important;
        }
        .mobile-header-sub-link-textonly-black {
          color: $black !important;
          font-size: 14px !important;
        }
        .sub-link {
          line-height: 40px;
        }
      }
    }

    .booking-detail-layout {
      .navbar .navbar-brand header {
        margin-top: 30px;
      }
    }

    .change-password-layout {
      .navbar .navbar-brand header {
        margin-top: 94px;
      }
    }

    .profile-layout {
      .navbar {
        height: 150px;
      }

      .navbar .navbar-brand header {
        margin-top: 125px;
      }
    }

    .new-booking-layout {
      .step-back-btn {
        top: -10px !important;
      }
    }
    .booking-summary-layout {
      main {
        padding-top: 0 !important;
      }
    }
    .booking-detail-layout {
      overflow: auto !important;

      main {
        height: calc(100vh - 180px);
        margin-top: -20px;
        padding-top: 10px !important;
      }

      .booking-preview-info {
        height: 100%;

        .booking-preview-row {
          height: 100%;
        }

        .col-booking-detail {
          height: auto;
        }

        .booking-event-section {
          width: 100%;
          height: 100%;
          margin-left: 0;
          padding: 0;
          height: calc(100% - 1px);

          .chat-list-container {
            max-height: 100%;

            .messages-list {
              height: 0%;
              padding-bottom: 20%;

              .vehicle-card {
                width: auto !important;
              }
            }

            .message-block {
              width: 100%;

              .message-header {
                width: 100%;
              }
            }
          }

          .input-message-block {
            position: fixed;
            width: calc(100% - 30px);
            bottom: 10px;
            background: #fff;

            .align-items-start {
              width: 100vw;
              border-top: 1px solid rgba(139, 149, 158, 0.3);
              margin-left: -30px;
              padding-left: 10px;
            }

            .empty-items-files {
              display: none !important;
              visibility: hidden;
            }

            .input-message-area {
              width: auto;
              max-width: none;
            }
          }
        }
      }
    }

    .no-title {
      display: block !important;

      .no-title-header {
        .no-title-back-btn-wrap {
          height: auto !important;

          a {
            position: absolute;
          }
        }
      }
    }

    .form-block {
      padding-right: 0;

      .form-row {
        width: auto;
        max-width: none;
        margin: 0;
      }
    }

    .notifications-loyout {
      main {
        padding: 10px 20px !important;
        margin-top: -20px;

        .btn-anchor {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .table-responsive {
        height: calc(100vh - 220px);
        overflow-x: hidden !important;
        overflow-y: scroll !important;
      }

      .sub-cat {
        margin-top: 5px;
        font-size: 14px !important;
        font-weight: 500;
      }

      .table-responsive::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }

      .table-responsive::-webkit-scrollbar-track {
        border-radius: 10px;
        background: transparent;
      }

      .table-responsive::-webkit-scrollbar-thumb {
        background-color: rgba(120, 128, 134, 0.5) !important;
        border-radius: 10px;
      }

      .data-table td .btn-anchor {
        vertical-align: top !important;
      }
      .title-cat {
        line-height: 1.5;
        span {
          vertical-align: bottom;
        }
      }
    }

    .client-chat-layout {
      // header {
      //   .back-btn {
      //     top: calc(50% - 29px) !important;
      //   }
      // }

      main {
        padding: 0 20px !important;
        margin-top: -20px;

        .client-chat-container {
          display: block;
          padding: 0;

          .client-chat-room {
            width: 100%;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            height: 100%;

            .client-messages-list {
              height: calc(100vh - 260px);
              padding: 0;
              flex: 1;
              overflow: auto;
              display: flex;
              flex-direction: column-reverse;

              .message .text-wrap .text {
                font-size: 16px;
                font-weight: 500;
              }

              .ScrollbarsCustom-Track {
                width: 5px !important;

                .ScrollbarsCustom-Thumb {
                  background-color: rgba(120, 128, 134, 0.25) !important;
                }
              }
            }

            .client-message-input-block {
              position: fixed;
              height: inherit;
              // bottom: 80px;
              bottom: 0;
              padding-bottom: 10px;
              left: 0;
              width: 100vw;
              border-top: unset;
              background: #fff;

              .message-input-toolbar {
                width: calc(100% - 60px);
                padding-left: 10px;
                display: flex;
              }
              .textarea-chat-control {
                max-height: 110px;
                overflow: auto;
              }
              .message-input-wrap {
                position: relative;
                flex-grow: 1;
                width: calc(100% - 55px);
              }

              .chat-footer-container {
                position: unset !important;
                max-width: unset !important;
              }

              .collected-files {
                margin: 0 5px 5px 0;
                display: flex;
                flex-direction: column;
                align-items: stretch;
                // max-height: 68px;
                overflow-y: auto;
                width: 100vw;
                padding-left: 20px;
                .file-preview {
                  flex: 1 1 100%;
                  justify-content: flex-start;
                  display: flex;
                  align-items: stretch;
                  margin-right: 20px;
                  .image-preview-label {
                    max-width: 100%;
                  }
                  .remove-file-btn {
                    margin-left: auto;
                  }
                }
              }
            }
          }
        }
      }
    }

    .company-logo-wrap {
      min-height: 100px;

      .company-logo-field {
        width: 200px;

        .company-logo {
          width: 80px;
          height: 80px;
          float: left;
        }

        .text-brown {
          margin-top: 28px;
        }
      }
    }
  }

  .partner-btn-desktop-tertiary {
    height: 50px !important;
    font-size: 16px !important;
  }
  .outsource-btn-partner {
    height: 35px !important;
    font-size: 16px !important;
  }
  .admin-chat-container
    .admin-chat-room
    .message-input-toolbar
    .send-btn-wrap
    .send-btn {
    margin-left: 10px;
  }

  .navbar .navbar-collapse.collapse,
  .navbar .navbar-collapse.collapse.in,
  .navbar .navbar-collapse.collapsing {
    display: none !important;
  }

  .badge.badge-outlined.badge-important {
    color: $danger;
    background-color: rgba($danger, 0.1);
    border-radius: 10px;
    font-size: 14.5px !important;
    font-weight: bold;
    text-shadow: none !important;
    letter-spacing: normal !important;
  }

  .mismatch-text {
    color: $danger;
    font-size: 15px;
    float: none;
  }

  .sidebar-container {
    position: fixed;
    display: block;
    top: 0;
    height: 100%;
    width: 260px;
    right: 0;
    left: auto;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    border-top: none;
    text-align: left;
    padding: 0;
    -webkit-transition: width 0.28s;
    transition: width 0.28s;

    @include transform-translate-x(260px);
    @include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    > ul {
      position: relative;
      z-index: 4;
      height: calc(100vh - 61px);
      width: 100%;
    }

    .sidebar-nav {
      margin-top: 40%;
    }
  }

  .partner-accepted-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    height: calc(100vh - 2rem);
    justify-content: center;

    p {
      margin-top: 1rem;
    }
  }

  .badge.badge-new-booking {
    margin: 0 !important;
    margin-bottom: 5px !important;
    padding: 7px 5px 5px 6px !important;
    font-size: 14px;
  }

  .badge.badge-accepted-dispatch {
    margin: 0 !important;
    margin-bottom: 5px !important;
    padding: 7px 5px 5px 6px !important;
    font-size: 14px;
  }
  .badge.badge-completed-dispatch {
    margin: 0 !important;
    margin-bottom: 5px !important;
    padding: 7px 5px 5px 6px !important;
    font-size: 14px;
  }
  .badge.badge-incomplete-dispatch {
    margin: 0 !important;
    margin-bottom: 5px !important;
    padding: 7px 5px 5px 6px !important;
    font-size: 14px;
  }

  .badge.badge-cancelled-dispatch {
    margin: 0 !important;
    margin-bottom: 5px !important;
    padding: 7px 5px 5px 6px !important;
    font-size: 14px;
  }

  .badge.badge-expired-dispatch {
    margin: 0 !important;
    margin-bottom: 5px !important;
    padding: 7px 5px 5px 6px !important;
    font-size: 14px;
  }
  .badge.badge-change-request-dispatch {
    margin: 0 !important;
    margin-bottom: 5px !important;
    padding: 7px 5px 5px 6px !important;
    font-size: 14px;
  }

  .badge.badge-completed-dispatch {
    margin: 0 !important;
    margin-bottom: 5px !important;
    padding: 7px 5px 5px 6px !important;
    font-size: 14px;
  }

  .benefits-member-message {
    background-color: $white;
    // border-radius: 6px;
    //box-shadow: 9px 9px 9px 9px rgba($black, 0.03);
    //@at-root: $default-shadow;
    box-shadow: $card-shadow;
    padding: 20px;
    width: 87.5%;
    border-radius: 2px;
    border: 1px solid rgba($dark-grey, 0.1);
    // border-top-color: #a79779;
    // border-right-color: #a79779;
    // border-bottom-color: #a79779;
    // border-left-color: #a79779;

    border-top-style: solid !important;
    border-right-style: solid !important;
    border-bottom-style: solid !important;
    border-left-style: solid !important;

    border-top-width: 0.5px !important;
    border-right-width: 0.5px !important;
    border-bottom-width: 0.5px !important;
    border-left-width: 0.5px !important;

    border-image-width: initial !important;
    border-image-source: initial !important;
    border-image-repeat: initial !important;
    border-image-outset: initial !important;
    border-image-slice: initial !important;
    .benefits-header {
      font-size: 14px !important;
      font-weight: 600 !important;
      word-wrap: break-word;
    }
    .benefits-body {
      font-size: 12px !important;
      //padding-top: 1%;
    }
  }

  .flightTracking-info {
    // background-color: $light-2;
    // box-shadow: $default-shadow;
    padding-left: 1.1rem;
    padding-right: 0.1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border: none;
    // border: 1.5px solid rgba($dark, 0.1);
    // border-radius: 2px;

    .flightTitle {
      font-size: 18px;
      // font-size: 16px;
      text-align: left;
      font-weight: lighter;
      color: $black;
    }
    .flightSubTitle {
      font-size: 14px !important;
      // font-size: 11px !important;
      text-align: left;
      color: $dark-2;
    }
    .flightStatus-delayed {
      font-size: 11px !important;
      // font-size: 8px !important;
      font-weight: 600 !important;
      display: block;
      box-sizing: content-box;
      border-radius: 2px;
      max-width: fit-content;
      color: white;
      background-color: $red;
      padding: 0.3rem 0.3rem 0.1rem 0.3rem;
      margin-bottom: 1.3rem;
      margin-top: 0.3rem !important;
      text-transform: uppercase;
    }
    .flightStatus-onTime {
      font-size: 11px !important;
      // font-size: 8px !important;
      font-weight: 600 !important;
      display: block;
      box-sizing: content-box;
      border-radius: 2px;
      max-width: fit-content;
      color: white;
      // background-color: $aquamarine;
      background-color: $success;
      padding: 0.25rem 0.3rem 0.1rem 0.3rem;
      margin-bottom: 1.3rem;
      margin-top: 0.3rem !important;
    }

    .flight-iata-departure {
      // font-size: 25px !important;
      max-width: 10%;
      font-size: 20px !important;
      font-weight: lighter;
      color: $black;
      margin-right: 1.75rem !important;
      margin-top: 0.2rem !important;
    }
    .flight-iata-arrival {
      // font-size: 25px !important;
      max-width: 10%;
      font-size: 20px !important;
      font-weight: lighter;
      color: $black;
      margin-left: 1rem !important;
      margin-top: 0.2rem !important;
    }
    .flight-time-green {
      // font-size: 20px !important;
      font-size: 18px !important;
      font-weight: lighter !important;
      // color: $aquamarine;
      color: $success;
      padding-top: 0.3rem;
    }
    .flight-time-red {
      // font-size: 20px !important;
      font-size: 18px !important;
      font-weight: lighter !important;
      color: $red;
      padding-top: 0.3rem;
    }
    .flight-inner-title {
      // font-size: 10px !important;
      // display: block;
      // font-size: 10px !important;
      // color: $dark-grey !important;
      // letter-spacing: 0.5px;
      // text-transform: uppercase;
      // margin-top: 0.3rem;

      line-height: 12px;
      font-size: 10px !important;
      font-weight: 400;
      letter-spacing: 0.5px;
      color: #8b959e !important;
      text-transform: uppercase;
      display: block;
      // margin-bottom: 0.3rem;
      margin-top: 0.3rem;
    }

    .flight-arrival-section {
      padding-left: 2rem !important;
    }
    .flight-section-border {
      border-right: 1px solid rgba(139, 149, 158, 0.25);
    }
    .flight-align-row {
      padding-left: 0.1rem;
    }
    .flight-gate {
      padding-left: 2rem !important;
    }
    .terminal-gate-title {
      // font-size: 10px !important;
      // font-size: 10px !important;
      // color: $dark-grey !important;
      // padding-top: 0.3rem;
      line-height: 12px;
      font-size: 10px;
      font-weight: 400;
      letter-spacing: 0.5px;
      color: $dark-grey;
      text-transform: uppercase;
      display: block;
      // margin-bottom: 0.3rem;
    }
    .terminal-gate-num {
      // font-size: 18px !important;
      font-size: 18px !important;
      // margin-bottom: 0.2rem;
      padding-top: 0.3rem;
    }
    .scheduled-time {
      font-size: 12px !important;
      text-align: left;
      color: $dark-2;
    }
    .progressBar {
      width: 100%;
      height: 0.75em;
      border: 0.125em solid darken(#3f3f3f, 3);
      background: darken(#3f3f3f, 5);
      overflow: hidden;
      // @include border-radius(25px);
    }

    .iata-width {
      max-width: 10% !important;
    }
    .myProgress {
      position: relative;
      width: 100%;
      height: 1px;
      background-color: #ddd;
      border-radius: 3px;
      background-color: $dark-grey;
    }

    .myBar-red {
      position: absolute;
      width: 1%;
      height: 100%;
      border-radius: 2px;
      background-color: $red;
      transition: width 2s;
    }

    .myBar-green {
      position: absolute;
      width: 1%;
      height: 100%;
      border-radius: 2px;
      // background-color: $aquamarine;
      background-color: $success;
      transition: width 2s;
    }

    .progress-dot-grey {
      height: 8px;
      width: 8px;
      background-color: $dark-grey;
      border-radius: 50%;
      display: inline-block;
    }
    .progress-dot-red {
      height: 8px;
      width: 8px;
      background-color: $red;
      border-radius: 50%;
      display: inline-block;
    }

    .progress-dot-green {
      height: 8px;
      width: 8px;
      // background-color: $aquamarine;
      background-color: $success;
      border-radius: 50%;
      display: inline-block;
    }

    .plane-position {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .plane-size {
      max-width: 20px !important;
      max-height: 20px !important;
      border-left: 1rem;
      border-color: white;
      border-width: 1rem;
    }
    .invalid-flight {
      font-size: 18px;
      text-align: left;
      font-weight: lighter;
      color: $black;
    }
    .dot-size {
      font-size: 7px;
    }
    .progress-time {
      font-size: 12px !important;
      // font-size: 8px !important;
      color: $dark-grey !important;
    }
    .time-bar {
      flex-wrap: wrap;
      padding-bottom: 1rem;
    }
  }

  .partner-header-home {
    margin: 0 8.5% !important;
    margin-top: 40px !important;
    justify-content: space-between;
    width: 100vw;
    padding: 0 !important;
  }

  .switch-client-banner {
    display: block !important;
    background-color: rgba($brown, 0.05);
    border: 1px solid $brown;
    border-radius: 2px;
    margin: 5.1%;
    padding: 20px;
    align-items: center;

    .close-div {
      display: flex;
      justify-content: end;
    }

    p {
      margin-bottom: 1rem;
      color: #636d77;
    }

    .close-icon {
      width: 10px;
      height: 10px;
    }
  }

  .meet-and-assist-container {
    border-radius: 2px;
    border: 1.5px solid rgba($dark-grey, 0.1);
    // border-color: $brown;
    box-shadow: $default-shadow;
    padding: 1.5em 1.5em;
    // margin-left: 4px;
    margin-top: 1em;

    .meet-and-assist-inner {
      border-radius: 2px;
      border: 1.5px solid rgba($dark-grey, 0.1);
      border-color: $brown;
      box-shadow: $default-shadow;
      padding: 11px 20px;
      margin-top: 1em;

      .inner-title {
        font-size: 14px;
        color: $dark-2;
        width: 80%;
        padding: 0;
        float: left;
      }
      .price {
        font-size: 14px;
        color: $dark-2;
        font-weight: normal;
        display: block;
        text-align: right;
      }
      .inner-description {
        font-size: 11px;
        color: $dark-2;
        display: block;
        width: 116px;
      }
      .meet-and-assist-payment {
        padding-top: 20px;
      }

      .custom-radio {
        margin-bottom: 10px;
        .custom-control-label {
          &:after {
            background-image: url('/images/icons/radio-golden.svg');
          }
        }
        .custom-control-input {
          &:checked {
            ~ .custom-control-label {
              &:after {
                background-image: url('/images/icons/radio-checked.svg');
              }
            }
          }
        }
      }
    }
  }

  .partner-tabs {
    display: flex;
    align-items: baseline;
    justify-content: right;
    margin: 5%;
    margin-top: 0;
  }
  .partner-tab {
    font-size: 12px;
    padding: 5px;
    width: 125px;
    text-align: center;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .info-icon-multi-stop {
    left: 7.1em !important;
  }
  .tab-active {
    background-color: black;
    color: white;
    border: 1px solid black;
  }
  .tab-inactive {
    background-color: white;
    color: rgba($dark, 1);
    border: 1px solid rgba($dark, 0.5);
  }

  .nav-open {
    .sidebar-container {
      @include transform-translate-x(0px);
    }

    .navbar .container {
      left: -260px;
    }

    .page-wrap {
      left: 0;
      @include transform-translate-x(-260px);
    }
  }

  .navbar-toggler .icon-bar {
    display: block;
    position: relative;
    background: #fff;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    margin: 0 auto;
  }

  .navbar-toggler {
    margin: 10px 15px 10px 0;
    width: 40px;
    height: 40px;
  }
  .bar1,
  .bar2,
  .bar3 {
    outline: 1px solid transparent;
  }
  .bar1 {
    top: 0px;
    @include bar-animation($topbar-back);
  }
  .bar2 {
    opacity: 1;
  }
  .bar3 {
    bottom: 0px;
    @include bar-animation($bottombar-back);
  }
  .toggled .bar1 {
    top: 6px;
    @include bar-animation($topbar-x);
  }
  .toggled .bar2 {
    opacity: 0;
  }
  .toggled .bar3 {
    bottom: 6px;
    @include bar-animation($bottombar-x);
  }

  @include topbar-x-rotation();
  @include topbar-back-rotation();
  @include bottombar-x-rotation();
  @include bottombar-back-rotation();

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 250px;
    content: '';
    z-index: 9999;
    overflow-x: hidden;
  }

  .form-control + .form-control-feedback {
    margin-top: -8px;
  }
  .navbar-toggler:hover,
  .navbar-toggler:focus {
    background-color: transparent !important;
  }
  .navbar-collapse.collapse {
    height: 100% !important;
  }
  .navbar-collapse.collapse.in {
    display: block;
  }
  .navbar-expand {
    justify-content: space-between;
  }
  .collapse,
  .navbar-toggler {
    display: block !important;
    border: none;
    float: right;
    padding-right: 11%;

    &:focus {
      outline: none;
    }
  }
  .navbar-header {
    float: none;
  }
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .app-content .page-wrap header .add-btn {
    width: 45px;
    height: 45px;
    top: 0;
  }

  .admin-booking-activity {
    position: absolute;
    top: 9em;
    left: calc(100vw - 4em);
    text-align: center;
    .admin-unread-indicator {
      position: relative;
      width: 10px;
      height: 10px;
      border-radius: 15px;
      background: red;
      color: red;
      font-size: 6px;
      font-weight: 600;
      text-align: center;
      top: -23px;
      right: -25px;
    }
  }

  .cancel-button-confirmation {
    margin-left: 0px !important;
    margin-right: 4px !important;
    margin-bottom: 6px !important;
  }
  .follow-button-confirmation {
    margin-left: 4px !important;
    margin-right: 4px !important;
    margin-bottom: 6px !important;
  }
  .delete-button-confirmation {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 6px !important;
  }
  .admin-chat-container {
    padding: 0;
    overflow-x: hidden;

    .admin-chat-room {
      flex-direction: column;
      overflow: hidden;
      width: calc(100% - 40px);

      .room-content {
        margin-top: 20%;
        // border-top: 1px solid rgba(12, 12, 12, 0.1);
      }

      .admin-message-input-block {
        width: calc(115vw - 20px);
        left: 0;
        // height: auto;
        // top: calc(100% - 8em);
        padding: 0px 15px 0 3px;
        border-top: unset;
        // bottom: 70px;
        bottom: 0px;
        position: fixed;
        .message-input-wrap {
          position: relative;
          flex-grow: 1;
          width: calc(100% - 55px);
          div div {
            max-height: 120px;
            textarea {
              overflow-y: auto !important;
            }
          }
        }
      }
      .message-input-toolbar {
        padding-bottom: 10px;
      }
      .room-header {
        width: calc(100vw - 10px);
        display: block;
        padding: 0;
        position: fixed;
        z-index: 1;
        background-color: #fff;
        border-bottom: 1px solid rgba(12, 12, 12, 0.1);

        .align-items-center {
          justify-content: space-between;
          width: calc(100vw - 10px);
          height: 93%;
        }

        .userLabel-plain {
          font-size: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 16px;
        }
        .userLabel-border-right {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          font-size: 16px;
          text-align: center;
          margin-right: 0;
          margin-top: 1em;
        }
        .text-brown {
          font-size: 13px;
          display: flex;
          margin-right: 0;
          text-align: center;
        }
        .room-title {
          display: flex;
          align-items: center;
        }
      }

      .admin-messages-list {
        height: auto;
        max-height: calc(100vh - 13rem) !important;
        margin-top: 5rem;
        overflow-x: hidden;
        padding: 20px 10px 80px 0;
      }
    }

    .chats-list-wrap {
      .chats-list {
        width: calc(100% - 1.4em) !important;
      }

      .spinner_wrapper {
        left: 45%;
      }

      .list-panel {
        width: 90%;

        .search-field-wrap .form-group .form-control {
          margin-left: 4%;
        }
      }
    }
  }
  .chatScrollDown {
    font-size: 12px;
    font-weight: 700;
    margin-left: 25%;
    height: 30px !important;
    border: 1px solid #d0021b;
    z-index: 99999;
    position: relative;
    top: 0px;
    color: #d0021b;
    border-radius: 5px;
    text-transform: uppercase;
    background-color: $white;
    &:hover {
      color: #d0021b !important;
    }
  }
  .chatScrollDownFiles {
    font-size: 12px;
    font-weight: 700;
    margin-left: 25%;
    margin-top: 5px;
    height: 30px !important;
    border: 1px solid #d0021b;
    z-index: 99999;
    position: relative;
    top: 0px;
    color: #d0021b;
    border-radius: 5px;
    text-transform: uppercase;
    background-color: $white;
    &:hover {
      color: #d0021b !important;
    }
  }
  .apiBadgeBookingMobile {
    color: #ffffff;
    // background: #a79779;
    background: $black;
    border-radius: 10px;
    max-width: 40px;
    min-width: 40px;
    text-align: center;
    font-family: $font-family-base;
    font-size: 12px;
    font-weight: bold;
    height: 16px;
    margin-top: 0.3rem;
    display: flex;
    align-items: center;
    padding-top: 2px;
    justify-content: center;
  }
}

.apply-button-mobile {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .notification-btn {
    display: none !important;
  }
  .table-responsive {
    min-height: 155px;
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }
  .auth-page {
    .image-container {
      width: 0%;
    }

    .image-container-new {
      width: 0%;
    }
    .scrollable-page-content {
      height: 100vh !important;
      width: 100%;
    }
    .auth-container {
      width: 100%;

      .auth-wrap {
        min-width: 160px;
        max-width: unset;
        padding: 0 30px;
        margin-top: 150px;
        height: 100vh;
        display: table-cell;
        vertical-align: middle;
        width: 100vw;

        .rolzo-login-footer {
          height: 70px;
          overflow: visible;
        }

        .rolzo-header {
          &:first-child {
            margin-top: 30px;
            text-align: left !important;
          }

          .height150 {
            opacity: 0;
            display: none;
          }

          h3 {
            font-size: 28px;
            font-weight: 400;
          }

          .text-dark {
            font-size: 16px;
            font-weight: 400;
          }

          .btn.btn-anchor {
            font-size: 16px;
            font-weight: 600;
            vertical-align: text-bottom;
          }
        }

        .btn.login-btn {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .auth-container-100 {
      .client-register-continue {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
      }

      .signup-back-btn {
        position: absolute;
        left: 20px;
        top: 20px;

        img {
          width: 40px;
        }
      }

      .form-block:first-child {
        padding-top: 10px;
      }

      .form-block {
        margin: 0 20px;
      }
    }

    .page-title {
      background-color: #ffffff !important;

      h1 {
        font-size: 1.2rem !important;
        padding: 70px 50px 0px 20px !important;
        margin-bottom: 0px;
      }
    }
  }
  .hide-bottom-button {
    display: none;
  }
  .continue-right {
    float: right;
  }
  .light-tabs-content .booking-col {
    max-width: 100%;
  }
  .booking-col {
    max-width: 100%;
    &:not(:first-child) {
      margin-left: 0;
    }

    .booking-card {
      //padding: 15px 15% 30px 25px;
      padding: 15px 0 20px 10px;
      border: none;
      border: 1px solid rgba($dark-grey, 0.25);
      border-radius: 2px;
      width: 100%;
      .card-block {
        width: 95%;
      }
      .card-body {
        padding: 0 10px 0px;
        .card-logo {
          display: block;
          width: 23px;
          margin-right: 12px;
          &.booking_car {
            width: 32px;
          }
          &.booking_hourly {
            width: 22px;
          }
        }
        .booking-title {
          margin-bottom: 10px !important;
          font-size: 16px;
          font-weight: 600;
        }

        .description {
          margin-bottom: 0px;
          font-size: 16px;
          font-weight: 400;
        }
      }

      .booking-img-wrap {
        display: none !important;
      }
      &:hover {
        box-shadow: none;
      }
    }
    .mobile-arrow {
      display: none;
    }
  }

  //  Side bar
  .client-info,
  .business_logo {
    display: none;
  }

  .keyboard-open {
    .sidebar-bottom-container {
      display: none;
    }

    .client-chat-layout {
      .client-messages-list {
        height: calc(100vh - 180px) !important;
      }

      .client-message-input-block {
        bottom: 5px !important;
        padding-bottom: 5px !important;
      }
    }

    .book-continue-btn {
      display: none;
    }
  }

  .remove-button-stops {
    height: 25px !important;
    width: 25px !important;
    left: 1px !important;
  }
  .sidebar-bottom-container-partner {
    height: 65px !important;
    display: flex;
    justify-content: space-around !important;
  }
  .sidebar-bottom-container {
    z-index: 100;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    height: 80px;
    border-top: 1px solid rgba($dark, 0.3);
    background: white;
    width: 100%;

    .bottom-nav-link {
      text-align: center;
      color: #8b959e;
      font-size: 10px;
      font-weight: 600;
      padding: 12px 8px 16px 8px !important;
      cursor: pointer;
      // &.active {
      //   position: relative;
      //   &::after {
      //     content: '';
      //     position: absolute;
      //     width: 100%;
      //     height: 4px;
      //     background-color: $brown;
      //     top: 0;
      //     left: 0;
      //     transition: all 0.3s ease-in-out;
      //   }
      // }
      img {
        margin: auto !important;
      }

      .selected-nav {
        color: black;
      }
    }

    .bottom-nav-img-wrapper {
      height: 25px;
      margin-top: 2px;
      width: 30px;
      margin-left: auto;
      margin-right: auto;

      .bottom-nav-link-img {
        height: 20px;
      }
    }

    .bottom-menu-unread-indicator {
      position: relative;
      width: 10px;
      height: 10px;
      border-radius: 15px;
      background: red;
      color: red;
      font-size: 6px;
      font-weight: 600;
      text-align: center;
      top: -23px;
      left: 16px;
    }
  }

  .sidebar-bottom-container-admin {
    z-index: 100;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    height: 70px;
    border-top: 1px solid rgba($dark, 0.3);
    background: white;
    width: 100%;

    .bottom-nav-link {
      text-align: center;
      color: #8b959e;
      font-size: 10px;
      font-weight: 600;
      padding: 12px 8px 16px 8px !important;
      cursor: pointer;

      img {
        margin: auto !important;
      }

      .selected-nav {
        color: black;
      }
    }

    .bottom-nav-img-wrapper {
      height: 25px;
      margin-top: 2px;
      width: 30px;
      margin-left: auto;
      margin-right: auto;

      .bottom-nav-link-img {
        height: 20px;
      }
    }

    .bottom-menu-unread-indicator {
      position: relative;
      width: 10px;
      height: 10px;
      border-radius: 15px;
      background: red;
      color: red;
      font-size: 6px;
      font-weight: 600;
      text-align: center;
      top: -23px;
      left: 16px;
    }
  }

  .custom-datepicker-calendar {
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      font-size: 16px !important;
    }

    .react-datepicker__month-container {
      .react-datepicker__header .react-datepicker__current-month {
        font-size: 14px;
      }
    }
  }

  .react-datepicker__time-list-item {
    font-size: 16px !important;
  }

  .driver-booking-container {
    margin: 5%;

    .driver-service-card {
      border-radius: 10px;
      border: 1.5px solid rgba($dark-grey, 0.2);
      background-color: $light-2;
      padding: 1.5em 1.5em;
      font-size: 16px;
      margin-top: 1em;

      .driver-service-card-body-container {
        margin-bottom: 50px;
        margin-top: 0;

        p {
          font-size: 16px !important;
        }

        .step {
          padding: 10px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          column-gap: 20px;
          padding-left: 0;
          padding-right: 0;
        }

        .driver-service-card-item-updates {
          display: grid;
          grid-template-columns: 10% 73%;
          grid-auto-flow: column;
          border: 1px solid rgba($dark-grey, 0.3);
          padding: 1.5em 1em;
          background: none;
          border-radius: 5px;
        }

        .updates-complete {
          grid-template-columns: 10% 80% !important;
          border: 1px solid rgba($dark-grey, 0.5);
        }
      }
    }

    .tabs-container {
      .custom-tabs-nav-driver {
        background: rgba($dark-grey, 0.15);
        padding: 7px 15px;
        border-radius: 4px;
        font-size: 13px;

        &.light-tabs {
          border-bottom: 1px solid rgba($dark-grey, 0.3);
          border-radius: 0;
          width: 100%;
          max-width: 1000px;
          background: transparent;
          font-size: 15px;
          padding: 0;
          .nav-link {
            background: transparent;
            border-radius: 0;
            font-size: 16px !important;
            font-weight: 600;
            color: #8b959e;
            opacity: 0.55;
            padding: 7px 7px 0px 7px;
            border: none;

            &.active {
              background: transparent;
              border: none;
              border-bottom: 2px solid $black;
              box-shadow: none;
              color: $dark-2;
              opacity: 1;
              margin-bottom: -2px;
              font-weight: 600;
            }

            &:focus {
              box-shadow: none;
            }

            &:not(:last-child) {
              margin-right: 10px !important;
            }
          }
        }
      }
    }
    .newOffer-message {
      font-family: $font-family-base;
      line-height: 12px;
      // font-size: 12px;
      font-size: 16px;
      font-weight: 500;
      color: #8b959e;
      display: block;
      margin-bottom: 0.3rem;
      white-space: pre;
    }
    .card-message {
      line-height: 12px;
      font-family: $font-family-base;
      font-size: 14px;
      font-weight: 400;
      color: #8b959e;
      display: block;
      margin-bottom: 0.3rem;
    }
    .offer-date {
      white-space: pre;
      // font-size: 12px;
      font-size: 16px;
      font-weight: 700;
    }
    .partner-action-title {
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 0.4em;
    }
    .partner-action-message {
      font-family: $font-family-base;
      line-height: 12px;
      font-size: 16px;
      font-weight: 500;
      color: #8b959e;
      display: block;
      margin-bottom: 0.5rem;
      white-space: pre;
    }
    .partner-action-counter {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: $red;
    }
    .counter {
      font-size: 22px;
      font-weight: 400;
    }
    .price-currency {
      font-size: 16px;
      white-space: pre;
    }
    .partner-bookingType {
      font-family: $font-family-base;
      line-height: 12px;
      font-size: 16px;
      font-weight: 700;
      display: block;
      margin-bottom: 0.3rem;
    }
  }
  .grey-theme-background-driver {
    background-color: rgba($dark-grey, 0.1) !important;
    height: calc(100vh - 60px) !important;
  }
  .grey-theme-background-partner {
    background-color: rgba($dark-grey, 0.1) !important;
  }
  .partner-btn-desktop-decline {
    max-width: 95px;
    min-width: 95px;
    height: 42px !important;
    font-size: 14px !important;
    font-weight: 600;
    border: 1px solid #a79779;
    color: #a79779 !important;
    background-color: rgba($dark-grey, 0);
    &:active,
    &:focus,
    &:hover {
      font-weight: 600;
      box-shadow: none !important;
      background-color: rgba(165, 151, 124, 0.15) !important;
      border: rgba(165, 151, 124, 0.15) !important;
      color: #a79779 !important;
    }
  }
}

@media (max-width: 768px) {
  .spinner_wrapper {
    top: 102px;
    z-index: 1;
  }

  .modal-dialog {
    width: calc(100% - 16px);
  }

  .submit-btn {
    font-size: 16px !important;
    font-weight: 600 !important;
    width: 100%;
  }

  .book-btn {
    font-size: 16px !important;
    font-weight: 600 !important;
    width: 100%;
  }

  .quote-book-btn {
    font-size: 16px !important;
    font-weight: 600 !important;
    width: 100%;
  }

  .submit-btn,
  .back-btn,
  .cancel-btn {
    margin-top: 15px !important;
    margin-bottom: 22px !important;
  }

  &.light-tabs-partner {
    border-bottom: 0 !important;
    border-radius: 0;
    width: 100%;
    max-width: 1000px;
    background: transparent;
    font-size: 16px;
    padding: 0;
    .nav-link {
      background: transparent;
      border-radius: 0;
      font-size: 16px;
      font-weight: 600;
      color: $dark-grey;
      opacity: 0.55;
      padding: 7px 0px 14px 0px !important;

      &.active {
        background: transparent;
        border-bottom: 3px solid $black;
        box-shadow: none;
        color: $dark-2;
        opacity: 1;
        margin-bottom: -2px;
        font-weight: 600;
      }

      &:not(:last-child) {
        margin-right: 10px !important;
      }
    }
  }

  .cancel-booking-modal {
    border: 1px solid rgba($dark-grey, 0.3) !important;
    .modal-header {
      border-bottom: none !important;

      .modal-title {
        width: 100%;

        .btn-link {
          margin-right: 5%;
          margin-top: 3%;

          img {
            width: 20px !important;
            height: 20px !important;
          }
        }
        .btn-link-draft-mobile {
          margin-right: 1%;
          margin-top: 3%;
          margin-bottom: 7%;
          img {
            width: 12px !important;
          }
        }
      }
      padding-bottom: 0px;
    }
    .modal-body {
      padding-bottom: 0 !important;

      .modal-description {
        font-size: 15px;
        color: #000000;
        text-align: center;
      }

      .modal-description-delete {
        font-size: 14px;
        color: $dark-grey-2;
        width: 88%;
        // margin-bottom: 0.8rem;
        margin: 0 auto 0.8rem auto;

        // text-align: center;
        // width: 57%;
        // margin: 0 auto;
      }
    }
    .modal-title-delete {
      display: flex;
      font-size: 20px;
      color: $black;
      text-align: center;
      font-weight: 400;
      margin-top: 2%;
      padding-bottom: 2%;
      justify-content: center;
      margin: 0 auto 0.8rem auto;
      width: 90%;
    }
    .modal-content {
      width: 80% !important;
      margin: auto !important;
    }
    .modal-footer {
      border-top: none !important;
      justify-content: center !important;
      padding-bottom: 50px !important;
      flex-direction: column-reverse;

      .select-btn {
        margin-top: 0px;
        font-size: 14px;
        min-width: 175px;
        height: 40px;
        font-weight: 900 !important;
      }

      .no-btn {
        margin-top: 10px;
        margin-left: 7px;
      }
    }
  }

  #logo-tooltip {
    display: none;
    top: 10px !important;
    left: 220px !important;
  }

  input.form-control {
    font-size: 16px;
  }

  .location-autocomplete-result,
  .autocomplete-result {
    font-size: 16px !important;
  }

  .vehicle-card.chat-vehicle-card {
    padding: 15px 20px !important;
    // border: 1px solid #A79779;
  }

  .vehicle-card.chat-vehicle-card:hover {
    cursor: pointer;
    border: 1px solid #000;
  }

  .vehicle-card {
    display: block;
    padding: 20px 30px !important;
    max-width: unset !important;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.04) !important;
    &.active {
      box-shadow: none;
    }
    .card-body {
      padding: 5px 0 !important;

      .sub-title {
        font-size: 12px !important;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 0 !important;
      }
    }

    .vehicle-img-value {
      border-bottom: 1px solid rgba($dark, 0.3);
    }

    .vehicle-value {
      font-size: 12px !important;
      font-weight: 500;

      img {
        height: 15px !important;
      }
    }
    .electric-vehicle-mobile {
      position: absolute;
      bottom: 110px !important;
      left: calc(100% - 110px) !important;
    }
    .electric-vehicle {
      font-size: 12px !important;
      font-weight: 500;

      img {
        height: 15px !important;
      }
    }

    .vehicle-title {
      font-size: 16px;
      font-weight: 600;
      color: $dark-2;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .card-title {
      margin-bottom: 0;
      line-height: 1.5 !important;
    }

    .card-action {
      padding: 0 !important;

      .lead {
        min-width: 85px;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        line-height: 1.5 !important;
      }
    }

    .vehicle-img-wrap {
      width: calc(100vw - 220px) !important;
      min-width: 100px;
      margin-left: 0 !important;
      max-height: 150px;

      img {
        max-height: 150px;
        object-fit: scale-down !important;
        object-position: left;
      }
    }
  }

  .vehicle-card .col.card-action .lead {
    font-size: 16px;
    font-weight: 600;
  }
  .total-title-new {
    font-size: 16px !important;
    color: $dark-2;
  }

  .vehicle-card.active {
    border: 1.5px solid #000;
  }
  .vehicle-card.inactive {
    // border: 1.5px solid #a79779;
  }
  // .vehicle-card-noclick {
  //   max-width: unset !important;
  // }
  .vehicle-card-noclick:hover {
    // max-width: unset !important;
    border-radius: unset !important;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.04) !important;
    border: 1px solid rgba($dark-grey, 0.1) !important;
  }

  .vehicle-card.car-rent .card-action {
    min-width: 50px;
  }

  .input-group {
    background-color: $light-2 !important;
    box-shadow: $default-shadow !important;
    .input-group-prepend {
      border-right: transparent !important;
      .input-group-text {
        border: 0.5px solid transparent;
        background-color: $light-2 !important;
        border-radius: 2px;
        border-right: transparent !important;
      }
    }
    input.form-control {
      box-shadow: none !important;
      border-left: transparent !important;
      padding-left: 0px !important;
      &:focus,
      &:hover {
        border-radius: 0px !important;
        outline: none !important;
        border-color: transparent !important;
        border-left: transparent !important;
        background-color: $light-2;
      }
    }
    .form-control {
      border-left: transparent !important;
      &:focus,
      &:hover {
        border-radius: 0px !important;
        outline: none !important;
        border-color: transparent !important;
        border-left: transparent !important;
        background-color: $light-2 !important;
        // box-shadow: $default-shadow !important;
      }
    }
    &:focus,
    &:hover {
      border: 0.5px solid transparent !important;
      background-color: #fbfbfb !important;
      border-radius: 1px;
      height: 52px;
      border-color: $black !important;
    }
  }

  .round-input-group {
    box-shadow: none !important;
    border: 1.5px solid rgba($dark-grey, 0.2);
    border-radius: 10px !important;
    height: 60px !important;
    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      border: 1px solid rgba($black, 1) !important;
      border-radius: 10px !important;
      border-color: rgba($black, 1) !important;
      height: 60px !important;
    }
    input.form-control {
      margin-top: 9px;
      border-radius: 10px !important;
    }
  }
  .round-date-group {
    border-radius: 10px;
    input.form-control {
      box-shadow: none !important;
      border: 1.5px solid rgba($dark-grey, 0.2);
      border-radius: 10px !important;
      height: 60px !important;
      &:hover,
      &:focus,
      &:active {
        border-radius: 10px !important;
        border: 1px solid rgba($black, 1);
        border-color: rgba($black, 1) !important;
      }
    }
  }
  .round-select-group {
    width: 100%;
    .react-select-container {
      .react-select__control {
        box-shadow: none !important;
        border: 1.5px solid rgba($dark-grey, 0.2);
        border-radius: 10px !important;
        height: 60px !important;
        font-size: 16px;
        &:hover,
        &:focus,
        &:active,
        &:focus-within {
          border-radius: 10px !important;
          border: 1px solid rgba($black, 1);
          border-color: rgba($black, 1) !important;
        }

        .react-select__dropdown-indicator {
          padding: 0 10px;

          img {
            width: 23px;
            height: 23px;
          }
        }
      }
      .react-select__menu {
        font-size: 16px !important;
      }
    }
  }
  .client-select-group {
    width: 100%;
    .react-select-container {
      font-size: 16px;
    }
  }

  .child-seat-sub-header {
    margin-bottom: 0px !important;
    width: auto !important;
    max-width: none !important;
  }

  .react-select-container {
    font-size: 16px !important;
  }

  .decline-offer {
    height: 100%;
    width: 100%;
    margin: 0;
  }

  .decline-offer-booking-modal {
    .modal-header {
      border-bottom: none !important;

      .modal-title {
        width: 100%;
        display: flex;
        padding-bottom: 0px;

        .close-btn-wrap {
          display: flex;
          width: 100%;
          justify-content: flex-end;
        }
      }
    }

    .modal-content {
      height: 100% !important;
      border: 0 !important;
      border-radius: 0 !important;
      padding: 0 !important;
    }

    .title {
      display: block;
      font-size: 20px;
      margin-bottom: 1em;
      margin-top: 0;
      color: #0c0c0c;
      font-weight: 400;
      text-align: left;
    }

    .modal-body {
      margin-right: 1rem;
      margin-left: 1rem;
      margin-top: 4rem;
      padding-left: 0;

      .modal-description {
        font-size: 15px;
      }
      .modal-options {
        padding-top: 1em;
      }
    }

    .modal-footer {
      border-top: none !important;
      justify-content: end !important;
      padding-bottom: 50px !important;
      margin: 0 !important;
    }

    .modal-footer > * {
      margin: 0 !important;
    }
  }

  .client-commission-modal {
    .modal-content {
      width: 100% !important;
    }

    .modal-header {
      border-bottom: none !important;
      padding: 2rem 1rem;

      .modal-title {
        width: 100%;
        display: flex;
        padding-bottom: 0px;

        .close-btn-wrap {
          display: flex;
          width: 100%;
          justify-content: flex-end;
        }
      }
    }

    .modal-body {
      margin-bottom: 4em;
      padding: 0;
      padding-left: 3em;
      padding-right: 3em;

      .price-text {
        text-align: center;
        font-size: 25px;
      }
      .commission-text {
        text-align: center;
        font-size: 15px;
        color: $green;
      }
      .description-text {
        text-align: center;
        font-size: 15px;
        margin-top: 1.5em;
        color: $dark-grey-2;
      }
    }
  }
  .whats-new-modal,
  .whats-new-modal-mobile {
    display: flex !important;
    justify-content: center;
    @media (max-width: 480px) {
      opacity: 1;
      background-color: #808080;
    }
    .modal-size {
      width: 350px;
      max-width: 350px;
    }
    .modal-content {
      height: fit-content;
      overflow-y: initial;
      border-radius: 0.3rem;
    }
    .modal-header {
      border-bottom: none !important;
      padding-right: 0;
      // padding-top: 20px;
      // padding-bottom: 30px;

      .modal-title {
        width: 100%;
        display: flex;
        padding-bottom: 0px;

        .close-btn-wrap {
          img {
            width: 12px;
            position: static;
          }
        }
      }
    }

    .modal-body {
      padding: 0 !important;
      display: block;

      .whatsnew {
        color: $brown;
        font-size: 15px;
        margin-bottom: 10px;
        align-self: start;
        margin-left: 0;
        margin-top: 0;
      }

      .title {
        font-size: 22px;
        margin-bottom: 10px;
        padding-right: unset;
        margin-left: 0;
        margin-right: 0;
      }

      .description {
        font-size: 15px;
        color: $dark-grey-2;
        margin-bottom: 5px;
        word-wrap: break-word;
        align-self: start;
        margin-left: 0;
        margin-right: 0;
        width: auto;
      }

      .cta {
        display: block;
        align-self: auto;
        margin-bottom: 0;
        margin-right: 0;

        a {
          padding-top: 9px;
          height: 40px;
          font-size: 16px !important;
          margin-bottom: 45px !important;
        }
      }
    }

    .modal-panel {
      flex-direction: column;
      height: 654px;
      max-height: 654px;

      .modal-div {
        max-width: none;
        max-height: 300px;
        flex: 1;
        overflow-y: auto;
        border-radius: 0.3rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        max-width: 100%;

        .image {
          height: 100%;
        }
      }

      .modal-container {
        height: max-content;
        padding-left: 25px;
        padding-right: 25px;
        flex: none;
        overflow-y: auto;
        max-height: 70vh;
      }
    }

    .modal-panel-update {
      flex-direction: column;

      .modal-div {
        max-width: none;
        flex: 1;
        overflow-y: auto;
        border-radius: 0.3rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        max-width: 100%;

        .image {
          height: 100%;
        }
      }

      .modal-container {
        height: max-content;
        padding-left: 25px;
        padding-right: 25px;
        flex: none;
        overflow-y: auto;
        max-height: 70vh;
      }
    }

    .update-img {
      width: 55%;
      height: 80%;
      margin-top: 20px;
      align-self: center;
    }

    .modal-div::-webkit-scrollbar,
    .modal-container::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  .partner-filters {
    border: 1.5px solid rgba($dark-grey, 0.2) !important;
    border-radius: 10px !important;
    height: 60px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 24px;
      height: 12px;
    }
  }

  .client-filters {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 24px;
      height: 12px;
    }
  }

  .partner-filter-container {
    margin-top: 8em;
  }

  .app-search-field {
    .partner-search-bar {
      height: 50px !important;
      background-color: $white !important;
    }
    .input-group {
      background-color: $light-2 !important;
      box-shadow: $default-shadow !important;
      .input-group-prepend {
        border-right: transparent !important;
        .input-group-text {
          border: 0.5px solid transparent;
          background-color: $white !important;
          border-radius: 2px;
          border-right: transparent !important;
        }
      }
      input.form-control {
        box-shadow: none !important;
        border-left: transparent !important;
        padding-left: 0px !important;
        &:focus,
        &:hover {
          border-radius: 0px !important;
          outline: none !important;
          border-color: transparent !important;
          border-left: transparent !important;
          background-color: $white;
        }
      }
      .form-control {
        border-left: transparent !important;
        &:focus,
        &:hover {
          border-radius: 0px !important;
          outline: none !important;
          border-color: transparent !important;
          // border-left: transparent !important;
          background-color: $white;
          // box-shadow: $default-shadow !important;
        }
      }
      &:focus,
      &:hover {
        border: 0.5px solid transparent;
        background-color: #fbfbfb !important;
        border-radius: 1px;
        border-color: $black !important;
      }
    }
  }
  .form-row {
    .credit-card-img {
      margin-top: 4px;
    }
  }
  .info-icon {
    width: 13px !important;
    margin-left: 6px;
    padding-bottom: 4px !important;
    padding-top: 0.5px !important;
    vertical-align: middle !important;
  }
  .info-icon-rentals {
    width: 15px !important;
    margin-left: 6px;
    padding-bottom: 4px !important;
    padding-top: 2px !important;
    vertical-align: middle !important;
  }
  .info-icon-vip {
    width: 12px !important;
    padding-bottom: 3px !important;
    margin-left: 4px !important;
  }
  .info-icon-total {
    width: 13px !important;
    padding-bottom: 4px !important;
  }

  .door-to-door-div {
    padding: 2rem 1.5rem;

    .door-to-door-cards {
      flex-direction: column;
    }

    .door-to-door-card {
      padding: 1rem 2rem;
      width: 340px;

      img {
        width: 22px !important;
        height: 22px !important;
        margin: 0 !important;
      }

      span {
        margin-top: 10px;
        font-size: 15px;
      }

      span:last-of-type {
        margin-top: 10px;
        font-size: 15px;
        width: 80%;
      }

      .book-text {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-decoration: none;
        gap: 4px;
        font-size: 14px;
        color: $brown;
        cursor: pointer;
        margin-top: 5px;

        img {
          width: 15px !important;
          height: 15px !important;
        }
      }
    }
  }

  .home-booking-details-modal {
    p {
      font-size: 20px;
    }
    .section-header {
      &.expandable {
        &:after {
          bottom: 35px;
          top: unset !important;
          width: 20px !important;
          height: 20px !important;
        }
      }
    }
    .smart-sugg-car-card {
      padding: 12px !important;
    }
  }
  .booking-details-mobile-btn {
    padding: 10px 48px 10px 48px;
    background-color: #a79779;
    border-radius: 2px;
    border: none;
    font-size: 15px;
    color: white;
    width: 100%;
  }

  .associated-bookings {
    padding: 24px;

    p {
      font-size: 22px;
    }
    .section-header {
      &.expandable {
        &:after {
          top: calc(50% - 15px) !important;
          width: 30px !important;
          height: 30px !important;
        }
      }
    }
    .bookings-grid {
      margin-top: 25px;
    }
    .booking-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 8px;

      .booking-cell {
        font-size: 15px !important;

        div {
          margin-bottom: 2px !important;
        }
      }
    }
    hr {
      margin-top: 0 !important;
      margin-bottom: 5px !important;
    }
    .badge {
      font-size: 14px !important;
      font-weight: 400 !important;
    }
  }
}

@media (max-width: 576px) {
  .vehicle-card {
    .col {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .card-action {
      .hint-text {
        margin-right: 15px;
      }
      .vehicle-selected {
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin-right: 14px;
      }
    }
  }

  .booking-preview-info {
    .care-info-column {
      margin-top: 20px;
    }
  }

  .quotes-rent-from .vehicle-card,
  .new-booking-shared-from .vehicle-card,
  .quotes-shared-from .vehicle-card,
  .car-rent-form .vehicle-card {
    margin-top: 40px;
  }

  .last-driver-section .custom-file-label .file-name {
    margin-top: 4px;
  }
}

@media (max-height: 576px) {
  .admin-chat-container {
    padding: 0;
    overflow-x: hidden;

    .admin-chat-room {
      flex-direction: column;
      overflow: hidden;
      width: calc(100% - 40px);

      .room-content {
        margin-top: 20%;
        // border-top: 1px solid rgba(12, 12, 12, 0.1);
      }

      .admin-message-input-block {
        width: calc(115vw - 20px);
        left: 0;
        // height: auto;
        // top: calc(100% - 8em);
        padding: 0px 15px 0 3px;
        border-top: unset;
        // bottom: 70px;
        bottom: 0px;
        position: fixed;
        .message-input-wrap {
          position: relative;
          flex-grow: 1;
          width: calc(100% - 55px);
          div div {
            max-height: 120px;
            textarea {
              overflow-y: auto !important;
            }
          }
        }
      }
      .message-input-toolbar {
        padding-bottom: 10px;
      }
      .room-header {
        width: calc(100vw - 10px);
        display: block;
        padding: 0;
        position: fixed;
        z-index: 1;
        background-color: #fff;
        border-bottom: 1px solid rgba(12, 12, 12, 0.1);

        .align-items-center {
          justify-content: space-between;
          width: calc(100vw - 10px);
          height: 93%;
        }

        .userLabel-plain {
          font-size: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 16px;
        }
        .userLabel-border-right {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          font-size: 16px;
          text-align: center;
          margin-right: 0;
          margin-top: 1em;
        }
        .text-brown {
          font-size: 13px;
          display: flex;
          margin-right: 0;
          text-align: center;
        }
        .room-title {
          display: flex;
          align-items: center;
        }
      }

      .admin-messages-list {
        height: auto;
        max-height: calc(100vh - 13rem) !important;
        margin-top: 5rem;
        overflow-x: hidden;
        padding: 20px 10px 80px 0;
      }
    }

    .chats-list-wrap {
      .chats-list {
        width: calc(100% - 5em) !important;
      }

      .spinner_wrapper {
        left: 45%;
      }

      .list-panel {
        width: 90%;

        .search-field-wrap .form-group .form-control {
          margin-left: 4%;
        }
      }
    }
  }
}

.vehicle-card-desktop.chat-vehicle-card {
  padding: 15px 20px !important;
}

.chat-vehicle-card:hover {
  cursor: pointer;
  border: 1px solid #000;
}
.vehicle-card-desktop:hover {
  cursor: pointer !important;
  border-radius: 2px;
  border: 1px solid rgba(139, 149, 158, 0.25);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1) !important;
}

.vehicle-card-noclick:hover {
  cursor: default !important;
  border-radius: unset !important;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.04) !important;
  border: 1px solid rgba($dark-grey, 0.1);
}

.vehicle-card-desktop {
  display: block;
  padding: 20px 30px !important;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.04) !important;

  .card-body {
    padding: 5px 0 !important;

    .sub-title {
      font-size: 12px !important;
      font-weight: 500;
      margin-bottom: 0 !important;
    }
  }

  .vehicle-img-value {
    border-bottom: 1px solid rgba($dark, 0.3);
  }

  .vehicle-value {
    font-size: 12px !important;
    font-weight: 500;

    img {
      height: 15px !important;
    }
  }
  .electric-vehicle-mobile {
    position: absolute;
    bottom: 110px !important;
    left: calc(100% - 100px) !important;
  }
  .electric-vehicle {
    font-size: 12px !important;
    font-weight: 500;

    img {
      height: 15px !important;
    }
  }

  .vehicle-title {
    font-size: 15px;
    font-weight: 400;
    color: $dark-2;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .card-title {
    margin-bottom: 0;
    line-height: 1.5 !important;
  }

  .card-action {
    padding: 0 !important;

    .lead {
      min-width: 85px;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      line-height: 1.5 !important;
    }
  }

  .vehicle-img-wrap {
    // width: calc(100vw - 220px) !important;
    min-width: 100px;
    margin-left: 0 !important;
    max-height: 150px;

    img {
      max-height: 150px;
      object-fit: scale-down !important;
      object-position: left;
    }
  }
}

.vehicle-card-desktop .col.card-action .lead {
  font-size: 15px;
  font-weight: 400;
}

.vehicle-card-desktop.active {
  border: 1.5px solid #000;
}
.vehicle-card-desktop.inactive {
  // border: 1.5px solid #a79779;
}

.vehicle-card-desktop.car-rent .card-action {
  min-width: 50px;
}

@media (min-width: 438px) {
  .benefits-div {
    max-width: 35%;
    padding-bottom: 7%;
    padding-top: 0%;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .benefits-member-message {
    background-color: $white;
    // border-radius: 6px;
    //box-shadow: 9px 9px 9px 9px rgba($black, 0.03);
    //@at-root: $default-shadow;
    box-shadow: $card-shadow;
    padding: 20px;
    width: 89.5%;
    border-radius: 2px;
    border: 1px solid rgba($dark-grey, 0.1);
    // border-top-color: #a79779;
    // border-right-color: #a79779;
    // border-bottom-color: #a79779;
    // border-left-color: #a79779;

    border-top-style: solid !important;
    border-right-style: solid !important;
    border-bottom-style: solid !important;
    border-left-style: solid !important;

    border-top-width: 0.5px !important;
    border-right-width: 0.5px !important;
    border-bottom-width: 0.5px !important;
    border-left-width: 0.5px !important;

    border-image-width: initial !important;
    border-image-source: initial !important;
    border-image-repeat: initial !important;
    border-image-outset: initial !important;
    border-image-slice: initial !important;
    .benefits-header {
      font-size: 14px !important;
      font-weight: 600 !important;
      word-wrap: break-word;
      white-space: nowrap;
    }
    .benefits-body {
      font-size: 12px !important;
      //padding-top: 1%;
    }
  }
}

@media (max-width: 380px) {
  .driver-booking-container {
    margin: 5%;

    .driver-service-card {
      border-radius: 10px;
      border: 1.5px solid rgba($dark-grey, 0.2);
      background-color: $light-2;
      padding: 1.5em 1.5em;
      font-size: 16px;
      margin-top: 1em;

      .driver-service-card-body-container {
        p {
          font-size: 16px !important;
        }

        .step {
          padding: 10px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          column-gap: 20px;
          padding-left: 0;
          padding-right: 0;
        }

        .driver-service-card-item-updates {
          display: grid;
          grid-template-columns: 10% 73%;
          grid-auto-flow: column;
          border: 1px solid rgba($dark-grey, 0.3);
          padding: 1.5em 1em;
          background: none;
          border-radius: 5px;
        }

        .updates-complete {
          grid-template-columns: 10% 80% !important;
          border: 1px solid rgba($dark-grey, 0.5);
        }
      }
    }

    .tabs-container {
      .custom-tabs-nav-driver {
        background: rgba($dark-grey, 0.15);
        padding: 7px 15px;
        border-radius: 4px;
        font-size: 13px;

        &.light-tabs {
          border-bottom: 1px solid rgba($dark-grey, 0.3);
          border-radius: 0;
          width: 100%;
          max-width: 1000px;
          background: transparent;
          font-size: 15px;
          padding: 0;
          .nav-link {
            background: transparent;
            border-radius: 0;
            font-size: 16px !important;
            font-weight: 600;
            color: #8b959e;
            opacity: 0.55;
            padding: 7px 7px 0px 7px;
            border: none;

            &.active {
              background: transparent;
              border: none;
              border-bottom: 2px solid $black;
              box-shadow: none;
              color: $dark-2;
              opacity: 1;
              margin-bottom: -2px;
              font-weight: 600;
            }

            &:focus {
              box-shadow: none;
            }

            &:not(:last-child) {
              margin-right: 10px !important;
            }
          }
        }
      }
    }
    .newOffer-message {
      font-family: $font-family-base;
      line-height: 12px;
      // font-size: 12px;
      font-size: 13px;
      font-weight: 500;
      color: #8b959e;
      display: block;
      margin-bottom: 0.3rem;
      white-space: pre;
    }
    .card-message {
      line-height: 12px;
      font-family: $font-family-base;
      font-size: 13px;
      font-weight: 400;
      color: #8b959e;
      display: block;
      margin-bottom: 0.3rem;
    }
    .offer-date {
      white-space: pre;
      // font-size: 12px;
      font-size: 13px;
      font-weight: 700;
    }
    .counter {
      font-size: 15px;
      font-weight: 400;
    }
    .price-currency {
      font-size: 13px;
      white-space: pre;
    }
    .partner-bookingType {
      font-family: $font-family-base;
      line-height: 12px;
      font-size: 13px;
      font-weight: 700;
      display: block;
      margin-bottom: 0.3rem;
    }
  }
}
.no-background-colour {
  background-color: inherit !important;
}

@media (max-width: 768px) {
  .partner-btn-desktop-red {
    max-width: 100% !important;
    min-width: 100% !important;
    font-weight: 500 !important;
    height: 42px !important;
    font-size: 14px !important;
    border: 1px solid $red !important;
    background-color: transparent !important;
    color: $red !important;
    &:hover {
      font-weight: 600;
      box-shadow: none !important;
      background-color: transparent !important;
      border: 1px solid $red !important;
      color: $red !important;
      cursor: pointer;
    }
  }
  .osm-trip-duration-container-b2c {
    margin-left: -60px !important;
  }
  .additional-earning-modal {
    display: flex;
    flex-direction: column-reverse;
  }
  .partner-modal-tab {
    // margin: 6.5px 0;
    // padding: 30px;
    // border-radius: 2px;
    // border: 1.5px solid rgba($dark-grey, 0.1);
    overflow: hidden;
    // width: 700px;

    .custom-tabs-nav {
      background: rgba($dark-grey, 0.15);
      padding: 7px 15px;
      border-radius: 4px;
      font-size: 13px;
      margin-bottom: 14px;

      .nav-link {
        background-color: transparent;
        color: rgba($dark-grey, 0.5);
        font-weight: 500;
        padding: 9px 18px;
        border-radius: 3px;

        &:hover {
          color: rgba($dark-grey, 0.7);
        }

        &.active {
          background-color: $white;
          color: $dark-3;
          box-shadow: $default-shadow;
        }
      }

      &.light-tabs {
        // border-bottom: 1px solid rgba($dark-grey, 0.3);
        border-bottom: none;
        border-radius: 0;
        width: 100%;
        max-width: 1000px;
        background: transparent;
        font-size: 15px;
        padding: 0;
        .nav-link {
          background: transparent;
          border-radius: 0;
          font-size: 14px;
          font-weight: 600;
          color: #8b959e;
          opacity: 0.55;
          padding: 7px 7px 0px 7px;
          border: none;

          &.active {
            background: transparent;
            border: none;
            border-bottom: 2px solid $black;
            box-shadow: none;
            color: $dark-2;
            opacity: 1;
            margin-bottom: -2px;
            font-weight: 600;
          }

          &:focus {
            box-shadow: none;
          }

          &:not(:last-child) {
            margin-right: 25px;
          }
        }
      }
    }

    .card-body {
      padding: 0;
      padding-left: 6px;
      label.expand-title {
        margin-bottom: 10px;
        font-size: 10px;
      }
      label.expand-title-service {
        font-size: 10px;
      }
      p {
        margin-bottom: 5px;
        font-size: 14px;
      }
    }
  }

  .earnings-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: 15px;
    padding-right: 0.938rem;
    padding-bottom: 1rem;

    .grid-item {
      box-shadow: $default-shadow;
      background-color: $light-2;
      border-color: transparent;
      padding: 20px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    // .top-left {
    //   grid-column: 1 / span 2;
    //   margin-bottom: 1rem;
    // }

    // .top-right {
    //   grid-column: 3 / span 2;
    //   margin-bottom: 1rem;
    //   display: grid;
    //   grid-template-rows: repeat(2, 1fr); /* creates two rows */
    //   gap: 10px; /* space between the grid items */

    //   .pending-expected {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //   }

    //   .pending-expected-body {
    //     font-size: 20px;
    //     display: flex;
    //     justify-content: flex-end;
    //     padding: 0px;

    //     .amount {
    //       font-weight: normal;
    //       margin-right: 0.3em;
    //     }

    //     .currency {
    //       font-weight: normal;
    //     }
    //   }
    // }

    .bottom-left,
    .bottom-center-left,
    .bottom-center-right,
    .bottom-right {
      grid-column: auto;
    }

    .card-header {
      font-size: 14px;
      margin-bottom: 0.5rem;
      padding: 0px;
      color: $dark-grey;
      background-color: transparent;
      border-bottom: none;
      margin-bottom: 2rem;
    }

    .card-body {
      font-size: 18px;
      display: flex;
      justify-content: flex-start;
      padding: 0px;

      .amount {
        font-weight: normal;
        margin-right: 0.3em;
      }

      .currency {
        font-weight: normal;
      }
    }

    .card-bottom {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .increase {
      color: $success;
      font-size: 0.8rem;
    }
    .decrease {
      color: $danger;
      font-size: 0.8rem;
    }
  }
  .client-payout-card {
    border-radius: 3px !important;
    border: 1px solid rgba($dark-grey, 0.2);
    // background-color: $light-2;
    // padding: 2rem 0rem 2rem 0rem;
    font-size: 15px;
    margin-top: 1em;
    width: 107%;
    margin-top: 0em !important;
  }
  .partner-payout-details {
    display: block;
    padding: 0.8rem 1rem 0rem 1rem;
  }
  .caret-black {
    position: absolute;
    top: 0px !important;
    width: 49px;
    height: 20px;
  }

  .caret-gold {
    position: absolute;
    top: 0px !important;
    width: 49px;
    height: 20px;

    filter: invert(73%) sepia(3%) saturate(2935%) hue-rotate(360deg)
      brightness(83%) contrast(86%);
  }

  .b2c-booking-title:first-of-type {
    margin-left: 0 !important;
  }
  .book-vehicle {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
  }
}
