.btn {
  height: 43px;
  font-size: 12px;
  border-radius: 2px;

  &-brown {
    &:not(:disabled) {
      font-size: 14px;
      color: #ffffff;
      &:hover {
        background-color: $brown-active;
      }
    }
    &:disabled {
      font-size: 14px;
      color: #ffffff;
    }
  }
  &-white {
    color: $brown-active !important;
    border: 1px solid $brown-active !important;
    background-color: $light-3 !important;
    font-weight: 500 !important;
  }
  &-link {
    padding: 0;
    height: auto;
  }
  &.benefits-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    border: none;
    cursor: pointer;
    color: $white;
    //font-size: 15px;
    font-weight: 500;
    padding: 0 2.5rem;
    white-space: nowrap;
    height: 40px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // font-size: 0.8em;
    // border: none;
    // cursor: pointer;
    // color: $white;
    // //font-size: 15px;
    // font-weight: 500;
    // padding: 0 3rem;
    // white-space: nowrap;
    // height: 24% !important;
    // width: 60% !important;
    &:disabled,
    &.disabled {
      opacity: 1;
      // cursor: not-allowed;
      cursor: none;
    }

    &.outlined-sbmt-btn {
      color: $brown;
      border: 1px solid $brown;
      background: transparent;
    }
  }

  &.benefits-btn,
  &.submit-btn,
  &.back-btn-alert-action,
  &.back-btn,
  &.cancel-btn,
  &.book-btn {
    //height: 50px;
    //margin-top: 28px;
    margin: 10px 0;
    font-weight: 500;
    &.h-40 {
      height: 40px;
    }
    &:hover {
      text-decoration: none;
    }
  }
  &.back-btn-alert-action {
    margin: 0; /* Exclude margin for this specific class */
  }
  &.submit-btn {
    border: none;
    cursor: pointer;
    color: $white;
    font-size: 15px;
    font-weight: 500;
    padding: 0 3rem;
    white-space: nowrap;
    height: 40px;
    &:disabled,
    &.disabled {
      opacity: 1;
      // cursor: not-allowed;
      background-color: #e6e0d4;
      pointer-events: none;
      // cursor: none;
    }

    &.outlined-sbmt-btn {
      color: $brown;
      border: 1px solid $brown;
      background: transparent;
    }
  }
  &.book-btn {
    border: none;
    cursor: pointer;
    color: $white;
    font-size: 15px;
    font-weight: 500;
    padding: 0 3rem;
    white-space: nowrap;
    height: 40px;
    &:disabled,
    &.disabled {
      opacity: 1;
      cursor: none;
    }

    &.outlined-sbmt-btn {
      color: $brown;
      border: 1px solid $brown;
      background: transparent;
    }
  }
  &.cancel-dispatch-btn {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding: 0 10px;
    white-space: nowrap;
    &:disabled,
    &.disabled {
      opacity: 1;
      cursor: none;
    }
    color: $brown;
    border: 1px solid $brown;
    background: transparent;
    height: 35px;

    &:hover {
      font-weight: 600;
      box-shadow: none !important;
      background-color: rgba(165, 151, 124, 0.15) !important;
      border: rgba(165, 151, 124, 0.15) !important;
      color: #a79779 !important;
    }
  }
  &.cancel-booking-modal-btn {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    margin-top: 0px;
    padding: 0 10px;
    min-width: 175px;
    white-space: nowrap;
    &:disabled,
    &.disabled {
      opacity: 1;
      cursor: none;
    }
    color: $brown;
    border: 1px solid $brown;
    background: transparent;
    height: 40px;
  }
  &.submit-btn-additional-charge {
    border: none;
    cursor: pointer;
    color: $white;
    font-size: 15px;
    font-weight: 500;
    padding: 0 2rem !important;
    white-space: nowrap;
    height: 40px;
    &:disabled,
    &.disabled {
      opacity: 1;
      // cursor: not-allowed;
      cursor: none;
    }

    &.outlined-sbmt-btn {
      color: $brown;
      border: 1px solid $brown;
      background: transparent;
    }
  }
  &.submit-btn-change-form {
    border: none;
    cursor: pointer;
    color: $white;
    font-size: 15px;
    font-weight: 500;
    padding: 0 2rem !important;
    white-space: nowrap;
    height: 40px;
    &:disabled,
    &.disabled {
      opacity: 1;
      // cursor: not-allowed;
      cursor: none;
    }

    &.outlined-sbmt-btn {
      color: $brown;
      border: 1px solid $brown;
      background: transparent;
    }
  }
  &.cancel-payment-btn {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding: 0 2.5rem;
    white-space: nowrap;
    &:disabled,
    &.disabled {
      opacity: 1;
      cursor: none;
    }
    color: $brown;
    border: 1px solid $brown;
    background: transparent;
    height: 40px;
    margin-left: 0.4rem;

    &:hover {
      font-weight: 600;
      box-shadow: none !important;
      background-color: rgba(165, 151, 124, 0.15) !important;
      border: rgba(165, 151, 124, 0.15) !important;
      color: #a79779 !important;
    }
  }

  &.sharedBooking-and-Refund-modal-btn {
    border: none;
    cursor: pointer;
    color: $white;
    font-size: 15px;
    font-weight: 500;
    padding: 0.2rem 1.5rem 0 1.5rem !important;
    margin-left: 1.5rem;
    white-space: nowrap;
    height: 40px;
    &:disabled,
    &.disabled {
      opacity: 1;
      // cursor: not-allowed;
      cursor: none;
    }

    &.outlined-sbmt-btn {
      color: $brown;
      border: 1px solid $brown;
      background: transparent;
    }
  }

  &.refund-modal-cancel-btn {
    border: 1px solid rgba($dark-grey, 0.25);
    cursor: pointer;
    color: $dark-grey;
    font-size: 15px;
    font-weight: 500;
    padding: 0.2rem 1.5rem 0 1.5rem !important;
    margin-left: 1.5rem;
    white-space: nowrap;
    height: 40px;
    &:disabled,
    &.disabled {
      opacity: 1;
      // cursor: not-allowed;
      cursor: none;
    }

    &.outlined-sbmt-btn {
      color: $dark-grey;
      border: 1px solid $dark-grey;
      background: transparent;
    }
    &:hover {
      border: 1px solid rgba($dark-grey, 1);
    }
  }

  &.rates-comparison {
    cursor: pointer;
    color: $dark-grey;
    font-size: 15px;
    font-weight: 500;
    padding: 0.2rem 1.5rem 0 1.5rem !important;
    white-space: nowrap;
    height: 30px;
    &:disabled,
    &.disabled {
      opacity: 1;
      cursor: none;
    }

    &.outlined-sbmt-btn {
      color: $dark-grey;
      border: 1px solid $dark-grey;
      background: transparent;
    }
    &:hover {
      border: 1px solid rgba($dark-grey, 1);
    }
  }

  &.rates-event-card {
    border: 1px solid rgba($dark-grey, 1);
    cursor: pointer;
    color: $dark-grey;
    font-size: 15px;
    font-weight: 500;
    padding: 0.2rem 1.5rem 0 1.5rem !important;
    white-space: nowrap;
    height: 30px;
    &:disabled,
    &.disabled {
      opacity: 1;
      cursor: none;
    }

    &.outlined-sbmt-btn {
      color: $dark-grey;
      border: 1px solid $dark-grey;
      background: transparent;
    }
  }

  &.rates-comparison-back {
    cursor: pointer;
    color: $dark-grey;
    font-size: 15px;
    font-weight: 500;
    padding: 0.2rem 1.5rem 0 1.5rem !important;
    white-space: nowrap;
    height: 30px;
    &:disabled,
    &.disabled {
      opacity: 1;
      cursor: none;
    }

    &.outlined-sbmt-btn {
      color: $dark-grey;
      border: 1px solid $dark-grey;
      background: transparent;
    }
  }

  &.dispatch-modal-btn {
    font-family: $font-family-base !important;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    border: 2px solid rgba($brown, 0.5);
    border-radius: 9px;
    cursor: pointer;
    color: $dark-grey;
    padding: 0.5rem 3rem 0 3rem !important;
    margin-left: 1.5rem;
    white-space: nowrap;
    height: 60px;
    &:disabled,
    &.disabled {
      opacity: 1;
      // cursor: not-allowed;
      cursor: none;
    }

    &.outlined-sbmt-btn {
      color: $dark-grey;
      border: 1px solid $dark-grey;
      background: transparent;
    }
    // &:hover {
    //   border: 1px solid rgba($dark-grey, 1);
    // }
  }

  &.bookingChangesAdmin-modal-btn {
    border: none;
    cursor: pointer;
    color: $white;
    font-size: 15px;
    font-weight: 500;
    padding: 0.2rem 1.5rem 0 1.5rem !important;
    white-space: nowrap;
    height: 40px;
    &:disabled,
    &.disabled {
      opacity: 1;
      // cursor: not-allowed;
      cursor: none;
    }

    &.outlined-sbmt-btn {
      color: $brown;
      border: 1px solid $brown;
      background: transparent;
    }
  }

  &.select-btn {
    height: 50px;
    min-width: 140px;
    border: 1px solid $brown;
    background-color: $brown;
    cursor: pointer;
    color: $light;
    font-weight: 500;

    &:disabled,
    &.disabled {
      // cursor: not-allowed;
      cursor: none;
    }
    &:hover,
    &:active {
      color: $white !important;
    }
  }
  &.toggle-btn {
    height: auto !important;
    font-size: 12px;
    border-radius: 2px;
    input {
      display: none;
    }
  }
  &.back-btn,
  &.cancel-btn {
    display: block;
    line-height: 20px;
    color: $dark-grey !important;
  }

  &.back-btn-brown {
    display: block;
    color: $brown !important;
    border: 1px solid $brown;
    width: 130px;
    height: 40px !important;
  }

  &.back-btn {
    width: 169px;
    border: 1px solid $dark-grey;
  }

  &.back-btn-alert-action {
    width: 145px;
    border: 1px solid $dark-grey;
  }

  &.alerts-delete-btn {
    font-size: 11px;
    width: 70px;
    height: 26px !important;
    border: 1px solid $dark-grey;
    float: right;
  }

  &.cancel-btn {
    font-size: 16px;
  }
}

.remove-btn {
  display: inline-block;
  width: 27px;
  height: 27px;

  &:hover {
    opacity: 0.8;
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.remove-col {
  vertical-align: top !important;
  padding-right: 5px !important;
  padding-top: 5px !important;
}

.remove-btn-img {
  width: 10px !important;
  height: 10px !important;
}

.btn-search {
  font-size: 15px !important;
}

.btn-geo {
  font-size: 14px !important;
  color: $brown !important;
  text-decoration: none;
  border-bottom: 0.1em solid;
  &:hover {
    text-decoration: none;
  }
}

.btn-anchor {
  position: relative;
  padding: 0;
  border: none;
  font-weight: 500;
  margin-top: 0.5rem;
  min-width: auto;
  font-size: 14px;
  height: 21px;
  margin-top: 3px;

  border-bottom: 0.1em solid;
  border-radius: 0;
  cursor: pointer;
  color: $brown;
  // border-color: #a7977980 !important;
  border-color: $brown !important;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0.1em;
    position: absolute;
    left: 0;
    bottom: -0.1em;
    border-radius: 50px;
    background-color: $brown !important;
    color: $brown !important;
    transition: all ease 0.5s;
  }
  &:hover {
    color: $brown !important;
  }
  &:hover:after {
    width: 100%;
  }
  &:focus {
    box-shadow: none;
    text-decoration: none;
  }
}
.payouts {
  .btn-anchor {
    margin-top: 4px;
    font-size: 14px !important;
    font-weight: 600;
  }
}
.btn-anchor-sharedBooking {
  position: relative;
  padding: 0;
  border: none;
  font-weight: 500;
  margin-top: 0.5rem;
  min-width: auto;
  font-size: 15px;
  height: 21px;
  margin-top: 3px;

  border-bottom: 0.1em solid;
  border-radius: 0;
  cursor: pointer;
  color: $brown;
  border-color: #a7977980 !important;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0.1em;
    position: absolute;
    left: 0;
    bottom: -0.1em;
    border-radius: 50px;
    background-color: $brown !important;
    color: $brown !important;
    transition: all ease 0.5s;
  }
  &:hover {
    color: $brown !important;
  }
  &:hover:after {
    width: 100%;
  }
  &:focus {
    box-shadow: none;
    text-decoration: none;
  }
}
.btn-anchor-black {
  position: relative;
  padding: 0;
  border: none;
  font-weight: 500;
  margin-top: 0.5rem;
  min-width: auto;
  font-size: 14px;
  height: 21px;
  margin-top: 3px;
  border-radius: 0;
  color: $black;
  cursor: default !important;
}
.btn-anchor-danger {
  position: relative;
  padding: 0;
  border: none;
  font-weight: 500;
  margin-top: 0.5rem;
  min-width: auto;
  font-size: 14px;
  height: 21px;
  margin-top: 3px;

  border-bottom: 0.1em solid;
  border-radius: 0;
  cursor: pointer;
  color: $danger;
  border-color: $danger !important;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0.1em;
    position: absolute;
    left: 0;
    bottom: -0.1em;
    border-radius: 50px;
    background-color: $danger !important;
    color: $danger !important;
    transition: all ease 0.5s;
  }
  &:hover {
    color: $danger !important;
  }
  &:hover:after {
    width: 100%;
  }
  &:focus {
    box-shadow: none;
    text-decoration: none;
  }
}
.btn-anchor-success {
  position: relative;
  padding: 0;
  border: none;
  font-weight: 500;
  margin-top: 0.5rem;
  min-width: auto;
  font-size: 14px;
  height: 21px;
  margin-top: 3px;

  border-bottom: 0.1em solid;
  border-radius: 0;
  cursor: pointer;
  color: $success;
  border-color: $success !important;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0.1em;
    position: absolute;
    left: 0;
    bottom: -0.1em;
    border-radius: 50px;
    background-color: $success !important;
    color: $success !important;
    transition: all ease 0.5s;
  }
  &:hover {
    color: $success !important;
  }
  &:hover:after {
    width: 100%;
  }
  &:focus {
    box-shadow: none;
    text-decoration: none;
  }
}
.btn-anchor-dark {
  position: relative;
  padding: 0;
  border: none;
  font-weight: 500;
  margin-top: 0.5rem;
  min-width: auto;
  font-size: 14px;
  height: 21px;
  margin-top: 3px;

  border-bottom: 0.1em solid;
  border-radius: 0;
  cursor: pointer;
  color: $black;
  border-color: $black !important;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0.1em;
    position: absolute;
    left: 0;
    bottom: -0.1em;
    border-radius: 50px;
    background-color: $black !important;
    color: $black !important;
    transition: all ease 0.5s;
  }
  &:hover {
    color: $black !important;
  }
  &:hover:after {
    width: 100%;
  }
  &:focus {
    box-shadow: none;
    text-decoration: none;
  }
}
.btn-anchor-empty-search {
  position: relative;
  padding: 0;
  border: none;
  font-weight: 500;
  margin-top: 0.5rem;
  min-width: auto;
  font-size: 15px;
  height: 23px;
  margin-top: 0;

  border-bottom: 0.1em solid;
  border-radius: 0;
  cursor: pointer;
  color: $brown;
  border-color: #a7977980 !important;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0.1em;
    position: absolute;
    left: 0;
    bottom: -0.1em;
    border-radius: 50px;
    background-color: $brown !important;
    color: $brown !important;
    transition: all ease 0.5s;
  }
  &:hover {
    color: $brown !important;
  }
  &:hover:after {
    width: 100%;
  }
  &:focus {
    box-shadow: none;
    text-decoration: none;
  }
}

.text-decoration-none {
  text-decoration: none;
}
a.btn-anchor {
  margin-top: 0px;
  &:hover {
    color: $brown !important;
  }
  &:focus {
    box-shadow: none;
    text-decoration: none;
  }
}

.fixed-btn-contain {
  position: fixed;
  bottom: 0px;
  margin-left: -15px;
  margin-right: -15px;
  padding: 0 15px;
  background: #f9f9f9;
  width: 100%;
}

.refresh-matrix {
  font-size: 14px;
  color: $dark-brown;
  &:hover {
    color: $brown-active !important;
  }
}

.promoCode-applied {
  // font: $promoCode-btn-front;
  .btn-promo {
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    font-size: 20px;
    border-radius: 0.3rem !important;
    line-height: 1.5;
  }
  .black-Button {
    border-radius: 2px;
    //border: 1px solid rgba(#000000, 0.1) !important;
    background-color: white !important;
    color: $black;
  }
  .promoCode-applied-span {
    vertical-align: middle !important;
    img {
      width: 14px;
      vertical-align: initial !important;
    }
    span {
      font-size: 15px;
      font-weight: 400;
    }
  }
}

.benefits-div {
  max-width: 5px;
  padding-top: 2.5%;
}
.phone-number-div {
  max-width: 495px;
}
.passenger-name-div {
  max-width: 187.5px;
}
.capacity-div {
  max-width: 250px;
}
.select-agent-btn {
  height: 30px;
  min-width: 60px;
  border: 1px solid $brown;
  background-color: $brown;
  cursor: pointer;
  color: $light;
  font-weight: 500;

  &:disabled,
  &.disabled {
    // cursor: not-allowed;
    cursor: none;
  }
  &:hover,
  &:active {
    color: $brown !important;
  }
}

.run-btn {
  min-width: 130px;
  border: 1px solid $black;
  cursor: pointer;
  font-weight: bolder;
  font-size: 14px;
  border-radius: 2px;
  height: 35px;
  background-color: $white;
  color: $black;

  &:hover,
  &:active {
    color: $white;
    background-color: $brown;
    border: 1px solid $brown;
  }
}
.chatScrollDown {
  font-size: 13px;
  font-weight: 700;
  margin-left: 40%;
  margin-bottom: 5px;
  height: 30px !important;
  border: 1px solid #d0021b;
  color: #d0021b;
  border-radius: 5px;
  text-transform: uppercase;
  background-color: $white;
  &:hover {
    color: #d0021b !important;
  }
}
.chatScrollDownFiles {
  font-size: 13px;
  font-weight: 700;
  margin-left: 40%;
  margin-bottom: 5px;
  height: 30px !important;
  border: 1px solid #d0021b;
  color: #d0021b;
  border-radius: 5px;
  text-transform: uppercase;
  background-color: $white;
  &:hover {
    color: #d0021b !important;
  }
}
.chatScrollDownData {
  img {
    margin-top: -2px;
    margin-right: 5px;
    width: 14px;
  }
}

.info-div {
  position: absolute;
  z-index: 1;
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: white;
  padding: 7px 10px 7px 10px;
  margin: 10px;
  font-size: 13px;
  max-width: 450px;
  margin-left: -373px;
  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
